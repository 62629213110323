import {
  BusinessUnitCreateInput,
  DivisionCreateInput,
  EmployeeCreateInput,
  LocationCreateInput, ScheduleCreateInput,
  ScheduleType
} from '../generated/graphql';


export const RequiredColumns: { [key: string]: string[] } = {
  businessUnit: ['name'],
  division: ['name'],
  location: ['name', 'region'],
  employee: ['firstName', 'email'],
  schedule: ['type', 'start', 'end'],
};

export const ColumnTypes: { [key: string]: { [key: string]: string } } = {
  businessUnit: {
    externalId: 'External ID',
    name: 'Name',
  },
  division: {
    externalId: 'External ID',
    name: 'Name',
  },
  location: {
    externalId: 'External ID',
    name: 'Name',
    region: 'Region',
    address: 'Address',
    lonLat: 'Lat/Lon',
    lon: 'Longitude',
    lat: 'Latitude',
  },
  employee: {
    externalId: 'External ID',
    // name: 'Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    title: 'Title',
    role: 'Role',
    email: 'Email',
    deskPhone: 'Desk Phone',
    mobilePhone: 'Mobile Phone',
    hireDate: 'Hire Date',
    terminationDate: 'Termination Date',
    hourlyWage: 'Hourly Wage',
    managerEmail: 'Manager Email',
  },
  schedule: {
    type: 'Type',
    start: 'Start',
    end: 'End',
  }
};

export interface ColumnData {
  businessUnit: {
    name?: string;
    externalId?: string
  };
  division: {
    name?: string;
    externalId?: string
  };
  location: {
    address?: string;
    name?: string;
    externalId?: string;
    lon?: string;
    region?: string;
    lonLat?: string;
    lat?: string
  };
  employee: {
    firstName?: string;
    lastName?: string;
    hireDate?: string;
    terminationDate?: string;
    role?: string;
    mobilePhone?: string;
    hourlyWage?: string;
    deskPhone?: string;
    externalId?: string;
    title?: string;
    managerEmail?: string;
    email?: string
  };
  schedule: {
    type?: ScheduleType;
    start?: string;
    end?: string;
  };
}

export interface CreateData {
  businessUnits: BusinessUnitCreateInput[];
  divisions: DivisionCreateInput[];
  locations: LocationCreateInput[];
  employees: EmployeeCreateInput[];
  schedules: ScheduleCreateInput[];
}

export interface ColumnMap {
  sheet: string;
  column: string;
  type: string;
  instance: string;
}

export function identifyWorkforceColumnType(sheet: string, column: string): ColumnMap | undefined {
  // Business Unit
  if (column.match(/Business Unit/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'businessUnit',
      'instance': 'name'
    };
  }

  // Division
  if (column.match(/Division/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'division',
      'instance': 'name'
    };
  }

  // Location
  if (column.match(/Location/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'location',
      'instance': 'name'
    };
  }
  if (column.match(/Region/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'location',
      'instance': 'region'
    };
  }
  if (column.match(/Address/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'location',
      'instance': 'address'
    };
  }
  if (column.match(/Latitude/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'location',
      'instance': 'lat'
    };
  }
  if (column.match(/Longitude/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'location',
      'instance': 'lon'
    };
  }

  // Employee
  if (column.match(/First Name/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'employee',
      'instance': 'firstName'
    };
  }
  if (column.match(/Last Name/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'employee',
      'instance': 'lastName'
    };
  }
  if (column.match(/Title/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'employee',
      'instance': 'title'
    };
  }
  if (column.match(/Role/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'employee',
      'instance': 'role'
    };
  }

  if (column.match(/Hire Date/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'employee',
      'instance': 'hireDate'
    };
  }
  if (column.match(/Termination Date/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'employee',
      'instance': 'terminationDate'
    };
  }
  if (column.match(/Email/i) && !column.match(/Manager/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'employee',
      'instance': 'email'
    };
  }
  if (column.match(/Hourly Wage/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'employee',
      'instance': 'hourlyWage'
    };
  }
  if (column.match(/Desk Phone/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'employee',
      'instance': 'deskPhone'
    };
  }
  if (column.match(/Mobile Phone/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'employee',
      'instance': 'mobilePhone'
    };
  }
  if (column.match(/Manager Email/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'employee',
      'instance': 'managerEmail'
    };
  }

  // Schedule
  if (column.match(/Schedule Type/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'schedule',
      'instance': 'type'
    };
  }
  if (column.match(/Schedule Start|Start Time/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'schedule',
      'instance': 'start'
    };
  }
  if (column.match(/Schedule End|End Time/i)) {
    return {
      'sheet': sheet,
      'column': column,
      'type': 'schedule',
      'instance': 'end'
    };
  }

  return undefined;
}
