import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/app';
import {selectWorkforceImport, setCurrentStep} from '../../../features/workforceImport/workforceImportSlice';
import Button from '@mui/material/Button';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {LoadingButton} from '@mui/lab';
import useWorkforceImport from '../../../features/workforceImport/useWorkforceImport';

export function WorkforceImportUpload() {
  const [loading, setLoading] = useState(false);
  const {uploadFile} = useWorkforceImport();
  const importState = useAppSelector(selectWorkforceImport);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (importState.file) {
      setLoading(false);
      if (importState.currentStep === 'upload') {
        dispatch(setCurrentStep('validate'));
      }
    } else {
      // setLoading(true);
    }
  }, [importState.file]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (!file) return;
    setLoading(true);
    uploadFile(file).then();
  };

  const uploadButton = (
    <Button variant="contained" component="label">
      Select Excel File
      <input hidden type="file" accept=".xlsx" onChange={handleFileUpload}/>
    </Button>
  );

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column',
      textAlign: 'center', justifyContent: 'center'
    }}>
      <Typography variant="h6" gutterBottom>Upload your Excel file</Typography>
      <Box sx={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
        {loading ? (
          <Skeleton variant="rectangular" width={60} height={40}/>
        ) : (
          <CloudUploadIcon style={{fontSize: '60px'}}/>
        )}
        <Box sx={{alignContent: 'center', display: 'grid', marginLeft: 2}}>
          {loading ? (
            <LoadingButton loading variant="contained" component="label">
              Uploading...
            </LoadingButton>
          ) : (
            uploadButton
          )}
        </Box>
      </Box>
    </Box>
  );
}