import {useState} from 'react';
import Stack from '@mui/material/Stack';
import {Box, Button, Chip, TextField} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface StringStackProps {
  items: string[],
  onUpdate: (value: string[]) => void
}

export function StringStack({items, onUpdate}: StringStackProps) {
  const [inputValue, setInputValue] = useState('');

  const handleAdd = () => {
    if (inputValue.trim() !== '') {
      onUpdate([...items, inputValue]);
      setInputValue('');
    }
  };

  return (<Stack
    direction="column"
    justifyContent="flex-start"
    alignItems="flex-start"
    spacing={2}
  >
    <Box sx={{
      // maxWidth: '400px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    }}>
      {items.map((v, i) => (
        <Chip key={i} label={v} color="primary" sx={{margin: '2px'}}
          onDelete={() => {
            return onUpdate(items.filter((v2) => {
              return v2 !== items[i];
            }));
          }}/>
      ))}
    </Box>
    <TextField size={'small'}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      placeholder="Add new item"
    />
    <Button onClick={handleAdd} startIcon={<AddIcon/>}>Add</Button>
  </Stack>
  );
}