import React, {useEffect, useState} from 'react';
import {Box, Button, Modal, TextField, Typography} from '@mui/material';
import {useCreateLocationMutation} from '../../../generated/graphql';
import {useAppDispatch} from '../../../hooks/app';
import {addMessage} from '../../../features/snackbar/snackbarSlice';
import {v4 as uuidv4} from 'uuid';
import Grid2 from '@mui/material/Unstable_Grid2';
import {ValidateRequiredString, ValidTextField} from '../../../components/common/form/ValidTextField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

interface NewLocationModalProps {
  open: boolean,
  handleClose: () => void,
}

const NewLocationModal = ({open, handleClose}: NewLocationModalProps) => {
  const dispatch = useAppDispatch();

  const [formValid, setFormValid] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [externalId, setExternalId] = useState('');
  const [address, setAddress] = useState('');
  const [region, setRegion] = useState('');
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);

  const [createLocationMutation] = useCreateLocationMutation();

  const clearForm = () => {
    setName('');
    setExternalId('');
  };

  const handleSubmit = () => {
    // Handle the submission logic here
    // For example: send this data to an API or update the state in a parent component
    console.log({name, externalId});
    createLocationMutation({
      variables: {
        input: {
          name,
          externalId: (externalId === '' ? undefined : externalId),
          address: (address === '' ? undefined : address),
          region: (region === '' ? undefined : region),
          lonLat: (latitude === null || longitude === null) ? undefined : {
            X: latitude || 0,
            Y: longitude || 0,
          },
        }
      },
    })
      .then(() => {
        dispatch(addMessage({
          id: uuidv4(),
          message: 'Location created successfully',
          severity: 'success',
          autoHideDuration: 3000,
        }));
        handleClose(); // Close the modal after submission
      })
      .catch((error) => {
        dispatch(addMessage({
          id: uuidv4(),
          message: error.message,
          severity: 'error',
          autoHideDuration: 3000,
        }));
      });
  };

  const validateLatitude = (value: string) => {
    if (value.length === 0) return true;

    if (value.length > 0 && !isNaN(parseFloat(value))) {
      const num = parseFloat(value);
      if (num >= -90 && num <= 90) {
        return true;
      }
    }
    return false;
  };
  const validateLongitude = (value: string) => {
    // console.log({value});
    if (value.length === 0) return true;

    if (value.length > 0 && !isNaN(parseFloat(value))) {
      const num = parseFloat(value);
      if (num >= -180 && num <= 180) {
        return true;
      }
    }
    return false;
  };
  const validateForm = (): boolean => {
    return ValidateRequiredString(name) &&
      validateLatitude(latitude?.toString() || '') &&
      validateLongitude(longitude?.toString() || '');
  };

  useEffect(() => {
    setFormValid(validateForm());
  }, [
    name, externalId,
    address, region,
    latitude, longitude,
  ]);

  return (
    <Modal open={open} onClose={() => {
      clearForm();
      handleClose();
    }}
    aria-labelledby="create-new-location"
    aria-describedby="modal-modal-description"
    >
      <Box sx={style} component={'form'}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create New Location
        </Typography>
        <Grid2 container spacing={2}>
          {/* Name */}
          <Grid2 xs={12} md={12}>
            <ValidTextField required fullWidth
              label="Name" value={name} setValue={setName}
              validate={ValidateRequiredString}
            />
          </Grid2>
          {/* External ID */}
          <Grid2 xs={12} md={12}>
            <TextField fullWidth
              label="External ID"
              value={externalId}
              onChange={(e) => setExternalId(e.target.value)}
            />
          </Grid2>
          {/* Address */}
          <Grid2 xs={12} md={12}>
            <TextField fullWidth
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid2>
          {/* Region */}
          <Grid2 xs={12} md={12}>
            <TextField
              label="Region"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
            />
          </Grid2>
          {/* Latitude and Longitude */}
          <Grid2 xs={12} md={6}>
            <ValidTextField type="number"
              label="Latitude" value={latitude?.toString() || ''}
              setValue={(e) => setLatitude(parseFloat(e))}
              validate={validateLatitude}
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <ValidTextField type="number"
              label="Longitude" value={longitude?.toString() || ''}
              setValue={(e) => setLongitude(parseFloat(e))}
              validate={validateLongitude}
            />
          </Grid2>
        </Grid2>
        <Button variant="contained" onClick={handleSubmit} disabled={!formValid}>
          Create
        </Button>
      </Box>
    </Modal>
  );
};

export default NewLocationModal;
