import {WinPerfCounterTest} from '../../../../../generated/graphql';
import {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import {StringStack} from '../../../../../components/common/StringStack';
import Grid from '@mui/material/Unstable_Grid2';
import CardContent from '@mui/material/CardContent';
import {Button, CardHeader, TextField, Typography} from '@mui/material';
import Stack from '@mui/material/Stack';

interface WinPerfCounterTestViewProps {
  data: Array<WinPerfCounterTest>,
  onUpdate: (value: WinPerfCounterTest[]) => void
}

export function WinPerfCounterTestView({data, onUpdate}: WinPerfCounterTestViewProps) {

  return (<>
    {/*  Add new test */}
    <Button onClick={() => {
      onUpdate([...data, {
        counters: [],
        instances: [],
        objectName: 'Object Name',
        measurement: 'Measurement',
        priority: 1000,
        failOnMissing: false,
        includeTotal: false,
        warnOnMissing: false,
      }]);
    }}>Add new test
    </Button>

    {/*  Test Rows */}
    <Grid container spacing={2}>
      {data.map((v, i) => {
        return (<Grid key={i} xs={12}>
          <WinPerfCounterTestRow value={v}
            onUpdate={(newValue) => {
              const newPrev = data.slice();
              newPrev[i] = newValue;
              onUpdate(newPrev);
            }}
            onDelete={() => {
              const newPrev = data.filter((_, index) => index !== i);
              // console.log({v, newPrev});
              onUpdate(newPrev);
            }}
          />
        </Grid>);
      })}
    </Grid>
  </>);
}


interface WinPerfCounterTestRowProps {
  value: WinPerfCounterTest,
  onUpdate: (value: WinPerfCounterTest) => void
  onDelete: () => void
}

export function WinPerfCounterTestRow({value, onUpdate, onDelete}: WinPerfCounterTestRowProps) {
  const [counters, setCounters] = useState(value.counters);
  const [instances, setInstances] = useState(value.instances);
  const [objectName, setObjectname] = useState(value.objectName);
  const [measurement, setMeasurement] = useState(value.measurement);
  const [priority, setPriority] = useState(value.priority);
  const [failOnMissing, setFailOnMissing] = useState(value.failOnMissing);
  const [includeTotal, setIncludeTotal] = useState(value.includeTotal);
  const [warnOnMissing, setWarnOnMissing] = useState(value.warnOnMissing);

  useEffect(() => {
    setCounters(value.counters);
    setInstances(value.instances);
    setObjectname(value.objectName);
    setMeasurement(value.measurement);
    setPriority(value.priority);
    setFailOnMissing(value.failOnMissing);
    setIncludeTotal(value.includeTotal);
    setWarnOnMissing(value.warnOnMissing);
  }, [value]);

  useEffect(() => {
    onUpdate({
      counters: counters,
      instances: instances,
      objectName: objectName,
      measurement: measurement,
      priority: priority,
      failOnMissing: failOnMissing,
      includeTotal: includeTotal,
      warnOnMissing: warnOnMissing,
    });
  }, [
    counters,
    instances,
    objectName,
    measurement,
    priority,
    failOnMissing,
    includeTotal,
    warnOnMissing,
  ]);

  return (<Card variant={'outlined'}>
    <CardHeader action={<Button onClick={onDelete}>Delete</Button>}/>
    <CardContent>
      <Grid container>
        <Grid xs={3}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <TextField label={'Object Name'} value={objectName}
              onChange={(e) => {
                if (e.target.value === '') {
                  return;
                }
                setObjectname(e.target.value);
              }}/>
            <TextField label={'Measurement'} value={measurement}
              onChange={(e) => {
                if (e.target.value === '') {
                  return;
                }
                setMeasurement(e.target.value);
              }}/>
            <TextField label={'Priority'} value={priority} type={'number'}
              onChange={(e) => {
                if (parseInt(e.target.value) <= 0) {
                  return;
                }
                setPriority(parseInt(e.target.value));
              }}/>
          </Stack>
        </Grid>
        <Grid xs={3}>
          <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Counters</Typography>
          <StringStack items={counters} onUpdate={setCounters}/>
        </Grid>
        <Grid xs={3}>
          <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>Instances</Typography>
          <StringStack items={instances} onUpdate={setInstances}/>
        </Grid>
      </Grid>
    </CardContent>
  </Card>);
}

