import React, {createContext, PropsWithChildren, useContext, useEffect} from 'react';
import UserService from '../services/user-service';
import {KeycloakTokenParsed} from 'keycloak-js';
import {useLocalStorage} from 'react-use';
import {ResourceRole} from '../resources/ResourceRole';
import {useAppSelector} from '../hooks/app';
import {selectAuth} from '../features/auth/authSlice';

export interface UserClaims {
  sub?: string;
  userId?: string;
  email?: string;
  token?: KeycloakTokenParsed;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userInfo?: any;
}

/*
 * The user access status and claims.
 */
export interface AuthorizedUser {
  isAuthenticated?: boolean;
  accessLevel: ResourceRole | null;
  userClaims?: UserClaims;
}

interface UserClaimsContextValues {
  authUserClaims: AuthorizedUser;
}

// Create the context
const UserClaimsContext = createContext<UserClaimsContextValues>({
  authUserClaims: {}
} as UserClaimsContextValues);

function getAccessLevel() {
  for (const role of [ResourceRole.ADMIN, ResourceRole.CLIENT_ADMIN, ResourceRole.SUPERVISOR, ResourceRole.USER]) {
    if (UserService.hasRole([role])) {
      return role;
    }
  }
  return null;
}

// Define the provider component
export function UserClaimsProvider({children}: PropsWithChildren) {
  const auth = useAppSelector(selectAuth);
  const {keycloak, initialized} = UserService.useKeycloak();
  const [userClaims = {} as UserClaims, setUserClaims, deleteUserClaims] = useLocalStorage<UserClaims>('user-claims');

  useEffect(() => {
    if (initialized && auth.isAuthenticated) {
      // console.log('Loading user info');
      keycloak.loadUserInfo()
        .then(() => {
          const claims = {
            sub: keycloak.subject || '',
            userId: UserService.getUserId(),
            email: UserService.getEmail(),
            token: keycloak.tokenParsed,
            userInfo: keycloak.userInfo,
          } as UserClaims;
          // console.log('User info loaded', claims);
          setUserClaims(claims);
        })
        .catch((err) => {
          deleteUserClaims();
          console.error(err);
        });
    } else {
      deleteUserClaims();
    }
  }, [initialized, auth.isAuthenticated]);

  return (
    <UserClaimsContext.Provider
      value={{authUserClaims: {isAuthenticated: auth.isAuthenticated, userClaims, accessLevel: getAccessLevel()}}}>
      {children}
    </UserClaimsContext.Provider>
  );
}

// Define a hook for easy access to the context
export function useUserClaims() {
  return useContext(UserClaimsContext);
}
