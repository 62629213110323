import {DnsQueryTest, DnsRecordType} from '../../../../generated/graphql';
import {GridColDef} from '@mui/x-data-grid';
import {ArrayCrudView} from './ArrayCrudView';

interface DNSTestsProps {
  value: Array<DnsQueryTest>,
  onUpdate: (value: (((prevState: Array<DnsQueryTest>) => Array<DnsQueryTest>) | Array<DnsQueryTest>)) => void
}

const sharedOptions: Partial<GridColDef> = {
  editable: true,
  type: 'number',
  headerAlign: 'center',
  align: 'center',
  flex: 1,
};

const columns: GridColDef[] = [
  {
    field: 'server',
    headerName: 'Server',
    editable: true,
    flex: 1,
    align: 'left',
  },
  {
    field: 'domain',
    headerName: 'Domain',
    editable: true,
    flex: 1,
    align: 'left',
  },
  {
    field: 'record_type',
    headerName: 'Record Type',
    editable: true,
    flex: 1,
    align: 'left',
  },
  {
    field: 'network',
    headerName: 'Network',
    editable: true,
    flex: 1,
    align: 'left',
    valueGetter: (params) => params.value === DnsNetwork.DnsNetworkTcp ? 'TCP' : params.value === DnsNetwork.DnsNetworkTcptls ? 'TCP-TLS' : 'UDP',
  },
  {
    field: 'timeout',
    headerName: 'Timeout',
    editable: true,
    ...sharedOptions,
  },
  {
    field: 'interval',
    headerName: 'Interval',
    ...sharedOptions,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    ...sharedOptions,
  }
];

export enum DnsNetwork {
  DnsNetworkTcp = 'tcp',
  DnsNetworkTcptls = 'tcp-tls',
  DnsNetworkUdp = 'udp'
}

export function DNSTestsView({value, onUpdate}: DNSTestsProps) {
  return (<ArrayCrudView columns={columns} rows={value} onUpdate={onUpdate}
    newValue={(): DnsQueryTest => ({
      record_type: DnsRecordType.A,
      server: '8.8.8.8',
      domain: 'google.com',
      timeout: 10,
      interval: 300,
      priority: 1000,
      network: DnsNetwork.DnsNetworkUdp
    })}
  />);
}