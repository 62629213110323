import {BusinessUnit, useGetBusinessUnitsLazyQuery} from '../../../generated/graphql';
import {useEffect, useState} from 'react';
import {fetchBusinessUnits} from '../business-unit/BuTable';

import {ValidatedSelect, ValidatedSelectProps} from '../../../components/common/form/ValidatedSelect';

type BusinessUnitDropdownProps = {
  variant?: 'standard' | 'filled' | 'outlined',
  setValue: (value: (string)) => void,
  validate: (value: string) => boolean,
  value: string,
}

export function BusinessUnitDropdown({
  variant = 'outlined',
  setValue, validate, value,
  ...props
}: BusinessUnitDropdownProps & Omit<ValidatedSelectProps, 'variant' | 'value' | 'onChange'>) {
  const [values, setValues] = useState<BusinessUnit[] | undefined>(undefined);
  const [getBusinessUnits, {loading, error}] = useGetBusinessUnitsLazyQuery({
    // fetchPolicy: 'network-only',
    fetchPolicy: 'no-cache',
    // nextFetchPolicy: 'cache-first',
  });
  const fetchData = fetchBusinessUnits(getBusinessUnits, 50, setValues);

  useEffect(() => {
    fetchData().then();
  }, []);

  return <ValidatedSelect
    onChange={setValue}
    values={values}
    validate={validate}
    variant={variant}
    disabled={!!(error || loading || !values)}
    value={value}
    {...props} />;
}