import {Button, Grid, Typography} from '@mui/material';
import {useI18nContext} from '../../hooks/useI18n';
import {loginAsync} from '../../features/auth/authSlice';
import {useAppDispatch} from '../../hooks/app';

export const LoginPage = () => {
  const {translate} = useI18nContext();
  const dispatch = useAppDispatch();

  return <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{pt: 1}}>
    <Typography sx={{ml: 1, mb: 3, textAlign: 'center', wordBreak: 'normal', fontSize: '1.5em'}}>
      <span>
        {translate('welcome_message_prefix')}
        <span style={{paddingLeft: '3px', paddingRight: '5px', fontFamily: 'BiomeProSemiBold'}}>
              TruExperience.
        </span>
      </span>
      <span>{translate('welcome_message_suffix')}</span>
    </Typography>
    <Grid container sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2}}>
      <Grid item xs={10} sm={8} md={6} lg={4}>
        <img src={'/img/truexperience.svg'} width="100%" height="auto" alt="TruExperience"/>
      </Grid>
    </Grid>
    <Button aria-labelledby="sign-in-label" variant="outlined" sx={{mt: 3}}
      onClick={() => dispatch(loginAsync())}>
      <span id="sign-in-label" style={{marginLeft: '4px'}}>
        {translate('sign_in_label')}
      </span>
    </Button>
  </Grid>;
};