import React from 'react';
import {isAuthenticated, loginAsync, logoutAsync} from '../../features/auth/authSlice';
import {Button} from '@mui/material';
import {useI18nContext} from '../../hooks/useI18n';
import {useAppDispatch, useAppSelector} from '../../hooks/app';

export function UserButtons() {
  const dispatch = useAppDispatch();
  const {translate} = useI18nContext();

  if (useAppSelector(isAuthenticated)) {
    return (
      <>
        <Button variant="contained" color='secondary'
          onClick={() => {
            dispatch(logoutAsync());
          }}>
          {translate('sign_out_label')}
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button variant="contained" color='secondary'
          onClick={() => dispatch(loginAsync())}
        >
          {translate('sign_in_label')}
        </Button>
      </>
    );
  }
}
