import React, { useState } from 'react';
import { Button, Snackbar } from '@mui/material';
import CopyIcon from '@mui/icons-material/ContentCopy';

type Props = {
  message?: string;
  data: never[];
};

const CopyToClipboardButton: React.FC<Props> = ({ data, message }) => {
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const handleCopy = async () => {
    try {
      const json = JSON.stringify(data, null, 2);
      await navigator.clipboard.writeText(json);
      setOpenSnackbar(true);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div>
      <Button
        startIcon={<CopyIcon />}
        variant="contained"
        color="primary"
        onClick={handleCopy}
      >
        {message || 'Copy to clipboard'}
      </Button>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Copied to clipboard!"
      />
    </div>
  );
};

export default CopyToClipboardButton;
