import {useEffect, useState} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {FormControlLabel, Switch, TextField} from '@mui/material';
import {Maybe} from '../../../../generated/graphql';

interface BasicTargetViewProps {
  title: string,
  defaultInterval: number,
  value?: Maybe<{ interval: number, priority: number }>,
  onUpdate: (value: { interval: number, priority: number } | null) => void,
}

export function BasicTargetView({title, defaultInterval, value, onUpdate}: BasicTargetViewProps) {
  const [enabled, setEnabled] = useState<boolean>(!value);
  const [interval, setInterval] = useState<number>(value?.interval || defaultInterval);
  const [priority, setPriority] = useState<number>(value?.priority || 1000);

  useEffect(() => {
    if (!value) {
      setEnabled(false);
      onUpdate(null);
    } else {
      setEnabled(true);
      setInterval(value.interval);
      setPriority(value.priority);
    }
  }, [value]);

  useEffect(() => {
    if (!enabled) {
      onUpdate(null);
    } else {
      onUpdate({interval, priority});
    }
  }, [enabled, interval, priority]);

  return (
    <Grid container margin={1}>
      <Grid xs={2}>
        <FormControlLabel label={title} sx={{alignItems: 'center', margin: 1}}
          control={<Switch checked={enabled} onChange={(e) => {
            setEnabled(e.target.checked);
          }}/>}/>
      </Grid>
      <Grid xs={2}>
        <TextField value={interval} label="Interval" type={'number'} disabled={!enabled}
          onChange={(e) => {
            setInterval(parseInt(e.target.value));
          }}/>
      </Grid>
      <Grid xs={2}>
        <TextField value={priority} label="Priority" type={'number'} disabled={!enabled}
          onChange={(e) => {
            setPriority(parseInt(e.target.value));
          }}/>
      </Grid>
    </Grid>
  );
}