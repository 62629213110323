import {HttpTestV2} from '../../../../generated/graphql';
import {GridColDef} from '@mui/x-data-grid';
import {ArrayCrudView} from './ArrayCrudView';

interface HttpTestsProps {
  value: Array<HttpTestV2>;
  onUpdate: (value: (((prevState: Array<HttpTestV2>) => Array<HttpTestV2>) | Array<HttpTestV2>)) => void;
}

const sharedOptions: Partial<GridColDef> = {
  editable: true,
  type: 'number',
  headerAlign: 'center',
  align: 'center',
  flex: 1,
};

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    editable: true,
    flex: 1,
    align: 'left',
  },
  {
    field: 'siteURL',
    headerName: 'URL',
    editable: true,
    flex: 1,
    align: 'left',
  },
  {
    field: 'description',
    headerName: 'Description',
    editable: true,
    flex: 1,
    align: 'left',
  },
  {
    field: 'timeout',
    headerName: 'Timeout',
    ...sharedOptions,
    type: 'string',
  },
  {
    field: 'bypassCache',
    headerName: 'Bypass Cache',
    ...sharedOptions,
    type: 'boolean',
  },
  {
    field: 'cronTrigger',
    headerName: 'Cron Trigger',
    ...sharedOptions,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    ...sharedOptions,
  },
  {
    field: 'tolerance',
    headerName: 'Tolerance',
    ...sharedOptions,
  },
  // {
  //   field: 'workflowClass',
  //   headerName: 'Workflow Class',
  //   ...sharedOptions,
  // },
];

export function HttpV2TestsView({value, onUpdate}: HttpTestsProps) {
  return (<ArrayCrudView columns={columns} rows={value} onUpdate={onUpdate}
    newValue={(): HttpTestV2 => ({
      name: '',
      siteURL: 'https://example.com',
      description: '',
      bypassCache: false,
      timeout: '00:00:10',
      cronTrigger: '*/3 * * * *',
      priority: 1000,
      tolerance: 0,
    })}
  />);
}
