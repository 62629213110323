import {GridRowSelectionModel} from '@mui/x-data-grid/models/gridRowSelectionModel';
import {
  BusinessUnit,
  BusinessUnitConnection,
  GetBusinessUnitsQuery,
  GetBusinessUnitsQueryVariables,
  useDeleteBusinessUnitMutation,
  useGetBusinessUnitsLazyQuery,
  useUpdateBusinessUnitMutation
} from '../../../generated/graphql';
import {useAppDispatch} from '../../../hooks/app';
import ApiErrorAlert from '../../../components/common/ApiErrorAlert';
import {FullFeaturedCrudGrid} from '../FullFeaturedCrudGrid';
import {GridColDef, GridRowId, GridRowModel, GridToolbarContainer} from '@mui/x-data-grid';
import {addMessage} from '../../../features/snackbar/snackbarSlice';
import {v4 as uuidv4} from 'uuid';
import {ApolloError, LazyQueryHookExecOptions, QueryResult} from '@apollo/client';
import {Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import {useEffect, useState} from 'react';
import NewBusinessUnitModal from './NewBusinessUnitModal';

const businessUnitColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Business Unit',
    minWidth: 200,
    editable: true,
    flex: 1,
  },
  {
    field: 'externalId',
    headerName: 'External Id',
    minWidth: 300,
    editable: true,
    flex: 1,
  },
];

interface BuTableProps {
  handleRowSelection: (rowSelectionModel: GridRowSelectionModel, selected: BusinessUnit[]) => void,
}

export function fetchBusinessUnits(getBusinessUnits: (options?: Partial<LazyQueryHookExecOptions<GetBusinessUnitsQuery, GetBusinessUnitsQueryVariables>>) => Promise<QueryResult<GetBusinessUnitsQuery, GetBusinessUnitsQueryVariables>>, pageSize: number, setValues: (value: (((prevState: (BusinessUnit[] | undefined)) => (BusinessUnit[] | undefined)) | BusinessUnit[] | undefined)) => void) {
  const queryResult = async (after: string | null): Promise<BusinessUnitConnection | undefined> => {
    try {
      const data = await getBusinessUnits({
        variables: {
          first: pageSize,
          after: after,
        },
      });
      return data.data?.businessUnits as BusinessUnitConnection;
    } catch (error) {
      // setError(error as ApolloError);
    }
  };

  return async () => {
    const data = await queryResult(null);
    let nodes = data?.nodes as BusinessUnit[];
    let pageInfo = data?.pageInfo;

    while (pageInfo && pageInfo?.hasNextPage) {
      const newData = await queryResult(pageInfo.endCursor);

      if (newData) {
        nodes = nodes.concat(newData.nodes as BusinessUnit[]);
        pageInfo = newData.pageInfo;
      } else {
        pageInfo = undefined;
      }
    }
    setValues(nodes);
  };
}

export function BuTable(props: BuTableProps) {
  const dispatch = useAppDispatch();
  const [values, setValues] = useState<BusinessUnit[] | undefined>(undefined);
  const [pageSize, setPageSize] = useState(10);
  const [getBusinessUnits, {loading, error}] = useGetBusinessUnitsLazyQuery({
    fetchPolicy: 'network-only',
    // nextFetchPolicy: 'cache-first',
  });
  const [deleteBusinessUnitMutation] = useDeleteBusinessUnitMutation();
  const [updateBusinessUnitMutation] = useUpdateBusinessUnitMutation();

  const fetchData = fetchBusinessUnits(getBusinessUnits, pageSize, setValues);

  // Gather all data for the table
  useEffect(() => {
    fetchData().then();
  }, [pageSize]);

  if (error) {
    return <ApiErrorAlert title="Error loading business units" possibleErrors={[
      error.message,
    ]}/>;
  }

  if (values === undefined) {
    return <>Loading...</>;
  }

  const processRowUpdate = (newRow: GridRowModel): Promise<GridRowModel> => {
    console.log('processRowUpdate');
    return new Promise((resolve, reject) => {
      updateBusinessUnitMutation({
        variables: {
          id: newRow.id,
          input: {
            name: newRow.name as string,
            externalId: newRow.externalId as string,
          }
        }
      })
        .then((value) => {
          dispatch(addMessage({
            id: uuidv4(),
            message: 'Business Unit updated',
            severity: 'success',
            autoHideDuration: 3000,
          }));

          fetchData()
            .then()
            .finally(() => {
              resolve(value.data?.updateBusinessUnit as BusinessUnit);
            });

        })
        .catch((error: ApolloError) => {
          if (error.message.includes('violates unique constraint')) {
            dispatch(addMessage({
              id: uuidv4(),
              message: 'Business Unit name and external id must be unique',
              severity: 'error',
              autoHideDuration: 3000,
            }));
            return;
          }

          if (error.message.includes('no changes')) {
            dispatch(addMessage({
              id: uuidv4(),
              message: 'No changes',
              severity: 'warning',
              autoHideDuration: 3000,
            }));
            return;
          }

          console.error(error);
          dispatch(addMessage({
            id: uuidv4(),
            message: 'Error updating business unit',
            severity: 'error',
            autoHideDuration: 3000,
          }));
          reject(error);
        });
    });
  };

  const handleDeleteClick = (id: GridRowId) => {
    console.log({action: 'delete', id});
    deleteBusinessUnitMutation({
      variables: {id: id}
    })
      .then(() => {
        dispatch(addMessage({
          id: uuidv4(),
          message: 'Business Unit deleted',
          severity: 'success',
          autoHideDuration: 3000,
        }));
      })
      .catch((error: ApolloError) => {
        if (error.message.includes('violates foreign key constraint')) {
          dispatch(addMessage({
            id: uuidv4(),
            message: 'Cannot delete business unit with divisions',
            severity: 'error',
            autoHideDuration: 3000,
          }));
          return;
        }

        console.error(error);
        dispatch(addMessage({
          id: uuidv4(),
          message: 'Error deleting business unit',
          severity: 'error',
          autoHideDuration: 3000,
        }));
      })
      .finally(() => {
        fetchData().then();
      });
  };

  function EditToolbar() {
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => {
      fetchData().then();
      setModalOpen(false);
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon/>} onClick={handleOpenModal}>
          Add record
        </Button>
        <NewBusinessUnitModal open={modalOpen} handleClose={handleCloseModal}/>
        <Button color={'primary'} startIcon={<RefreshIcon/>} onClick={() => {
          fetchData().then();
        }}>
          Refresh
        </Button>
      </GridToolbarContainer>
    );
  }

  return (
    <FullFeaturedCrudGrid
      loading={loading}
      rows={values}
      columns={businessUnitColumns}
      handleRowSelection={props.handleRowSelection}
      processRowUpdate={processRowUpdate}
      handleDeleteClick={handleDeleteClick}
      slots={{
        toolbar: EditToolbar,
      }}
      onPaginationModelChange={(pagination) => {
        if (pagination.pageSize !== pageSize) {
          setPageSize(pagination.pageSize);
        }
      }}
    />
  );
}

