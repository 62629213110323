import React, {useState} from 'react';
import {Box, Button, Modal, Typography} from '@mui/material';
import {EmployeeCreateInput, useCreateEmployeeMutation} from '../../../generated/graphql';
import {useAppDispatch} from '../../../hooks/app';
import {addMessage} from '../../../features/snackbar/snackbarSlice';
import {v4 as uuidv4} from 'uuid';
import {EmployeeForm} from './EmployeeForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

interface NewEmployeeModalProps {
  open: boolean,
  handleClose: () => void,
}

const NewEmployeeModal = ({open, handleClose}: NewEmployeeModalProps) => {
  const dispatch = useAppDispatch();

  const [formValid, setFormValid] = useState<boolean>(false);
  const [employee, setEmployee] = useState<EmployeeCreateInput>({
    name: '',
    email: '',
    externalId: null,
    title: null,
    role: null,
    deskPhone: null,
    mobilePhone: null,
    businessUnitId: '',
    divisionId: '',
    locationId: '',
    managerId: null,
  });

  const [createEmployeeMutation] = useCreateEmployeeMutation();

  const clearForm = () => {
    setEmployee({
      name: '',
      email: '',
      externalId: null,
      title: null,
      role: null,
      deskPhone: null,
      mobilePhone: null,
      businessUnitId: '',
      divisionId: '',
      locationId: '',
      managerId: null,
    });
  };

  const handleSubmit = () => {
    // Handle the submission logic here
    // For example: send this data to an API or update the state in a parent component
    console.log({employee});

    createEmployeeMutation({
      variables: {
        input: employee,
      },
    })
      .then(() => {
        dispatch(addMessage({
          id: uuidv4(),
          message: 'Employee created successfully',
          severity: 'success',
          autoHideDuration: 3000,
        }));
        handleClose(); // Close the modal after submission
      })
      .catch((error) => {
        dispatch(addMessage({
          id: uuidv4(),
          message: error.message,
          severity: 'error',
          autoHideDuration: 3000,
        }));
      });
  };

  return (
    <Modal open={open} onClose={() => {
      clearForm();
      handleClose();
    }}
    aria-labelledby="create-new-employee"
    aria-describedby="modal-modal-description"
    >
      <Box sx={style} component={'form'}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create New Employee
        </Typography>
        <EmployeeForm employee={employee} onUpdate={(employee, valid) => {
          setEmployee(employee);
          setFormValid(valid);
        }}/>
        <Button variant="contained" onClick={handleSubmit} disabled={!formValid}>
          Create
        </Button>
      </Box>
    </Modal>
  );
};

export default NewEmployeeModal;

