/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import {useDrop} from 'react-dnd';
import {Box, Chip, Paper, Typography} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../../../hooks/app';
import {
  addColumnMap,
  deleteColumnMap,
  selectWorkforceImport,
} from '../../../../features/workforceImport/workforceImportSlice';
import {DraggableColumnProps} from './SourceColumnDraggable';
import {ColumnMap} from '../../../../services/import-service';

interface DropZoneProps {
  onDrop: (data: { props: DraggableColumnProps }) => void;
}

// Component for the drop zone
// @ts-ignore
const DropZone = ({onDrop}: DropZoneProps) => {
  const [{canDrop, isOver}, drop] = useDrop(() => ({
    accept: 'wfi-column',
    // @ts-ignore
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;

  return (
    <Box ref={drop} data-testid="dustbin"
      display={'flex'} justifyContent={'center'} alignItems={'center'}
      sx={{
        padding: 1,
        color: 'background.paper',
        flexGrow: 1,
        borderStyle: 'dashed',
        borderColor: isOver ? 'primary.dark' : 'primary.main',
        backgroundColor: isActive ? 'primary.dark' : 'background.paper',
        // backgroundColor: isOver ? 'primary.dark' : 'primary.main',
      }}
    >
      {isOver ? <Typography>Release to drop</Typography> : <Typography>Drag a file here</Typography>}
    </Box>
  );
}
;

interface SelectedColumnProps {
  column: ColumnMap;
}

const SelectedColumn = (props: SelectedColumnProps) => {
  const dispatch = useAppDispatch();

  const deleteMap = () => {
    // Remove the map from the list
    dispatch(deleteColumnMap({
      sheet: props.column.sheet,
      column: props.column.column,
    }));
  };

  return (
    <Box sx={{display: 'flex', gap: 1}} padding={1}>
      <Chip variant="outlined" onDelete={deleteMap} color={'primary'}
        label={`${props.column.type}.${props.column.instance}`}/>
    </Box>
  );
};

interface DualColumnComponentProps {
  // onDrop: (id: any) => void;
  sheet: string;
  column: string;
}

// Main component
const DualColumnComponent = (props: DualColumnComponentProps) => {
  const dispatch = useAppDispatch();
  const workforceImport = useAppSelector(selectWorkforceImport);

  const id = `${props.sheet}-${props.column}`;
  const found = workforceImport.columnMap.find((item) => {
    return item.sheet == props.sheet && item.column == props.column;
  });

  const handleDrop = (data: { props: DraggableColumnProps }) => {
    // Handle the drop event as needed
    const newItem: ColumnMap = {
      sheet: props.sheet,
      column: props.column,
      type: data.props.type,
      instance: data.props.column,
    };
    dispatch(addColumnMap(newItem));
  };

  return (
    <Box id={id} sx={{display: 'flex', gap: 1}} padding={1}>
      {/* Text Column */}
      <Paper sx={{
        // flex: '1 1',
        p: 1, width: 200,
        display: 'flex', flexDirection: 'column', justifyContent: 'center',
      }}>
        <Typography variant="body1" fontSize={14}>{props.column}</Typography>
        {/*<Typography>Some additional text or information can go here.</Typography>*/}
      </Paper>

      {/* Drop Zone Column */}
      <Paper sx={{
        // flex: '1 1',
        p: 1, width: 200,
        display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}>
        {found ? <SelectedColumn column={found}/> : <DropZone onDrop={handleDrop}/>}
      </Paper>
    </Box>
  );
};

export default DualColumnComponent;
