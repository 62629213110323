import {useAppDispatch, useAppSelector} from '../../hooks/app';
import {
  addComplete,
  addError,
  addExisting,
  addFailed,
  addMissing,
  selectWorkforceImport,
  setColumnMap,
  setDone,
  setFile,
  setParsedData,
  setRowData
} from './workforceImportSlice';
import {
  BusinessUnit,
  BusinessUnitCreateInput,
  Division,
  DivisionCondition,
  DivisionCreateInput,
  Employee,
  EmployeeCreateInput,
  Location,
  LocationCreateInput,
  Schedule,
  ScheduleCreateInput,
  useCreateBusinessUnitMutation,
  useCreateDivisionMutation,
  useCreateEmployeeMutation,
  useCreateLocationMutation,
  useCreateScheduleEntryMutation,
  useGetBusinessUnitsLazyQuery,
  useGetDivisionsLazyQuery,
  useGetEmployeeByEmailLazyQuery,
  useGetEmployeesLazyQuery,
  useGetLocationsLazyQuery,
  useGetSchedulesLazyQuery,
  useUpdateEmployeeAssociationMutation
} from '../../generated/graphql';
import {chunk} from 'lodash';
import {ApolloError} from '@apollo/client';
import {parseSchedule, parseWorkforceImport} from './parser';
import {read, utils} from 'xlxs';
import {ColumnMap, identifyWorkforceColumnType} from '../../services/import-service';
import {useEffect, useState} from 'react';

interface ReviewState {
  lookup: boolean;
  lookupStarted: boolean;
  import: boolean;
  importStarted: boolean;
}

const defaultReviewState = () => ({
  lookup: false,
  lookupStarted: false,
  import: false,
  importStarted: false,
});

const useWorkforceImport = () => {
  const importState = useAppSelector(selectWorkforceImport);
  const dispatch = useAppDispatch();
  const [importRunning, setImportRunning] = useState<boolean>(false);
  const [locationState, setLocationState] = useState<ReviewState>(defaultReviewState());
  const [businessUnitState, setBusinessUnitState] = useState<ReviewState>(defaultReviewState());
  const [divisionState, setDivisionState] = useState<ReviewState>(defaultReviewState());
  const [employeeState, setEmployeeState] = useState<ReviewState>(defaultReviewState());
  const [employeeManagerState, setEmployeeManagerState] = useState<ReviewState>(defaultReviewState());
  const [scheduleParseState, setScheduleParseState] = useState<ReviewState>(defaultReviewState());
  const [scheduleState, setScheduleState] = useState<ReviewState>(defaultReviewState());

  // Location
  useEffect(() => {
    if (importRunning) {
      if (!locationState.lookup && !locationState.lookupStarted) {
        setLocationState({lookup: false, lookupStarted: true, import: false, importStarted: false});
        lookUpExistingLocations().then().catch(console.error);
      }

      if (locationState.lookup && !locationState.import && !locationState.importStarted) {
        setLocationState({...locationState, import: false, importStarted: true});
        importLocations().then().catch(console.error);
      }
    }
  }, [importRunning, locationState]);

  // Business Unit
  useEffect(() => {
    if (importRunning) {
      if (locationState.import && !businessUnitState.lookup && !businessUnitState.lookupStarted) {
        setBusinessUnitState({lookup: false, lookupStarted: true, import: false, importStarted: false});
        lookUpExistingBusinessUnits().then().catch(console.error);
      }

      if (locationState.import && businessUnitState.lookup && !businessUnitState.importStarted) {
        setBusinessUnitState({...businessUnitState, import: false, importStarted: true});
        importBusinessUnits().then().catch(console.error);
      }
    }
  }, [locationState.import, businessUnitState]);

  // Division
  useEffect(() => {
    if (importRunning) {
      if (businessUnitState.import && businessUnitState.lookup && !divisionState.lookupStarted) {
        setDivisionState({lookup: false, lookupStarted: true, import: false, importStarted: false});
        lookUpExistingDivisions().then().catch(console.error);
      }
      if (divisionState.lookup && !divisionState.import && !divisionState.importStarted) {
        setDivisionState({...divisionState, import: false, importStarted: true});
        importDivisions().then().catch(console.error);
      }
    }
  }, [businessUnitState.import, divisionState]);

  // Employee
  useEffect(() => {
    if (importRunning) {

      if (divisionState.import && divisionState.lookup && !employeeState.lookup && !employeeState.lookupStarted) {
        setEmployeeState({lookup: false, lookupStarted: true, import: false, importStarted: false});
        lookUpExistingEmployees().then().catch(console.error);
      }

      if (divisionState.import && employeeState.lookup && !employeeState.importStarted) {
        setEmployeeState({lookup: true, lookupStarted: true, import: false, importStarted: true});
        importEmployees().then().catch(console.error);
      }
    }
  }, [divisionState.import, employeeState]);

  // Employee Manager
  useEffect(() => {
    if (importRunning) {
      if (employeeState.import && importState.status.employee.complete.length > 0 &&
        !employeeManagerState.lookup && !employeeManagerState.lookupStarted) {
        setEmployeeManagerState({lookup: false, lookupStarted: true, import: false, importStarted: false});
        associateEmployeeManagers().then().catch(console.error);
      }
    }
  }, [employeeState.import, importState.status.employee.complete]);

  // Schedule Parse
  useEffect(() => {
    if (importRunning) {
      if (employeeState.import && !scheduleParseState.lookup && !scheduleParseState.lookupStarted) {
        setScheduleParseState({lookup: false, lookupStarted: true, import: false, importStarted: false});
        parseScheduleData().then().catch(console.error);
      }
    }
  }, [employeeState.import]);

  // Schedule
  useEffect(() => {
    if (importRunning) {
      if (scheduleParseState.lookup && !scheduleState.lookup && !scheduleState.lookupStarted) {
        setScheduleState({lookup: false, lookupStarted: true, import: false, importStarted: false});
        lookUpExistingSchedules().then().catch(console.error);
      }

      if (employeeState.import && scheduleState.lookup && !scheduleState.importStarted) {
        setScheduleState({...scheduleState, import: false, importStarted: true});
        importSchedules().then().catch(console.error);
      }
    }
  }, [scheduleParseState.lookup, scheduleState]);

  const uploadFile = async (file: File): Promise<void> => {
    // Here, send the file to your server, or read the file if processing client-side
    const reader = new FileReader();

    reader.onload = (e) => {
      // Process workbook and extract the data
      const data = e.target?.result;
      const workbook = read(data, {type: 'binary'});
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const jsonData: { [key: string]: any } = {};
      workbook.SheetNames.forEach((sheetName: string) => {
        jsonData[sheetName] = utils.sheet_to_json(workbook.Sheets[sheetName]);
      });
      dispatch(setFile(jsonData));

      // Parse the column data
      const columns: ColumnMap[] = [];
      workbook.SheetNames.forEach((sheetName: string) => {
        const keys: string[] = [];

        // Look for missing keys
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        jsonData[sheetName].forEach((row: any) => {
          const rowkeys = Object.keys(row);
          rowkeys.forEach((key) => {
            if (keys.indexOf(key) < 0) {
              keys.push(key);
            }
          });
        });

        // Try to identify the column type
        keys.forEach((key) => {
          const colMap = identifyWorkforceColumnType(sheetName, key);
          if (colMap) {
            columns.push(colMap);
          }
        });
      });
      dispatch(setColumnMap(columns));
    };

    reader.readAsBinaryString(file);
  };

  const parseData = async (): Promise<void> => {
    const {data, rows} = parseWorkforceImport(importState);
    dispatch(setParsedData(data));
    dispatch(setRowData(rows));
  };

  const importData = async (): Promise<void> => {
    // await reviewImport();

    console.log('importData');
    setImportRunning(true);

    // await lookUpExistingLocations();
    // await lookUpExistingBusinessUnits();
    // await lookUpExistingEmployees();
    //
    // await importLocations();
    // await importBusinessUnits();

  };

  const reviewImport = async (): Promise<void> => {
    console.log('reviewImport');
    setImportRunning(false);

    setLocationState(defaultReviewState());
    setBusinessUnitState(defaultReviewState());
    setDivisionState(defaultReviewState());
    setEmployeeState(defaultReviewState());
    setEmployeeManagerState(defaultReviewState());
    setScheduleParseState(defaultReviewState());
    setScheduleState(defaultReviewState());
  };

  // Locations

  const [getLocations] = useGetLocationsLazyQuery();
  const [createLocationMutation] = useCreateLocationMutation();

  const fetchExistingLocations = async (locations: LocationCreateInput[]): Promise<Location[] | undefined> => {
    try {
      const data = await getLocations({
        variables: {
          first: locations.length,
          condition: {
            or: locations.map((location) => ({
              name: location.name
            })),
          }
        },
        fetchPolicy: 'network-only',
      });
      data.data?.locations.nodes.forEach((location) => {
        dispatch(addExisting({
          type: 'location',
          data: location,
        }));
      });
      return data.data?.locations.nodes as Location[];
    } catch (error) {
      console.error(error);
    }
  };

  const lookUpExistingLocations = async (): Promise<void> => {
    console.log('lookUpExistingLocations');
    const chunks = chunk(importState.parsedData.locations, 50);
    const existingLocations: Location[] = [];

    for (let i = 0; i < chunks.length; i++) {
      try {
        const items = await fetchExistingLocations(chunks[i]);
        if (items) {
          existingLocations.push(...items);
        }
      } catch (error) {
        console.error(error);
      }
    }

    // Save missing locations to state
    importState.parsedData.locations.forEach((location) => {
      const index = existingLocations.findIndex(value => {
        return value.name === location.name;
      });
      if (index === -1) {
        dispatch(addMissing({
          type: 'location',
          data: location,
        }));
      }
    });

    setLocationState({lookup: true, lookupStarted: true, import: false, importStarted: false});
  };

  const importLocations = async (): Promise<void> => {
    console.log('importLocations');
    const missing = importState.status.location.missing;
    for (let i = 0; i < missing.length; i++) {
      const input = missing[i];
      try {
        const resp = await createLocationMutation({
          variables: {
            input: input,
          }
        });

        if (resp.data?.createLocation) {
          dispatch(addComplete({
            type: 'location',
            data: resp.data.createLocation,
          }));
        } else if (resp.errors?.length) {
          dispatch(addFailed({
            type: 'location',
            data: input,
            error: resp.errors[0].message,
          }));
        }
      } catch (error) {
        dispatch(addFailed({
          type: 'location',
          data: input,
          error: (error as ApolloError).message,
        }));
      }
    }

    setLocationState({lookup: true, lookupStarted: true, import: true, importStarted: true});
    dispatch(setDone({type: 'location', done: true}));
  };

  function findLocId(name: string | undefined): string | undefined {
    if (name) {
      const locations: Location[] = [...importState.status.location.existing, ...importState.status.location.complete];
      const loc = locations.find((loc: Location) => loc.name === name);
      if (loc) {
        return loc.id;
      }
    }
    return undefined;
  }

  // Business Units

  const [getBusinessUnits] = useGetBusinessUnitsLazyQuery();
  const [createBusinessUnitMutation] = useCreateBusinessUnitMutation();

  const fetchExistingBusinessUnits = async (businessUnits: BusinessUnitCreateInput[]): Promise<BusinessUnit[] | undefined> => {
    try {
      const data = await getBusinessUnits({
        variables: {
          first: businessUnits.length,
          condition: {
            or: businessUnits.map((businessUnit) => ({
              name: businessUnit.name
            })),
          }
        },
        fetchPolicy: 'network-only',
      });
      data.data?.businessUnits.nodes.forEach((businessUnit) => {
        dispatch(addExisting({
          type: 'businessUnit',
          data: businessUnit,
        }));
      });
      return data.data?.businessUnits.nodes as BusinessUnit[];
    } catch (error) {
      console.error(error);
    }
  };

  const lookUpExistingBusinessUnits = async (): Promise<void> => {
    console.log('lookUpExistingBusinessUnits');
    const chunks = chunk(importState.parsedData.businessUnits, 50);
    const existingBusinessUnits: BusinessUnit[] = [];

    for (let i = 0; i < chunks.length; i++) {
      try {
        const items = await fetchExistingBusinessUnits(chunks[i]);
        if (items) {
          existingBusinessUnits.push(...items);
        }
      } catch (error) {
        console.error(error);
      }
    }

    // Save missing businessUnits to state
    importState.parsedData.businessUnits.forEach((businessUnit) => {
      const index = existingBusinessUnits.findIndex(value => {
        return value.name === businessUnit.name;
      });
      if (index === -1) {
        dispatch(addMissing({
          type: 'businessUnit',
          data: businessUnit,
        }));
      }
    });

    setBusinessUnitState({lookup: true, lookupStarted: true, import: false, importStarted: false});
  };

  const importBusinessUnits = async (): Promise<void> => {
    console.log('importBusinessUnits');
    const missing = importState.status.businessUnit.missing;
    for (let i = 0; i < missing.length; i++) {
      const input = missing[i];
      try {
        const resp = await createBusinessUnitMutation({
          variables: {
            input: input,
          }
        });

        if (resp.data?.createBusinessUnit) {
          dispatch(addComplete({
            type: 'businessUnit',
            data: resp.data.createBusinessUnit,
          }));
        } else if (resp.errors?.length) {
          dispatch(addFailed({
            type: 'businessUnit',
            data: input,
            error: resp.errors[0].message,
          }));
        }
      } catch (error) {
        dispatch(addFailed({
          type: 'businessUnit',
          data: input,
          error: (error as ApolloError).message,
        }));
      }
    }

    setBusinessUnitState({lookup: true, lookupStarted: true, import: true, importStarted: true});
    dispatch(setDone({
      type: 'businessUnit',
      done: true,
    }));
  };

  function findBusinessUnitId(name: string | undefined) {
    if (name) {
      const businessUnits: BusinessUnit[] = [...importState.status.businessUnit.existing, ...importState.status.businessUnit.complete];
      const obj = businessUnits.find((loc: BusinessUnit) => loc.name === name);
      if (obj) {
        return obj.id;
      }
    }
    return undefined;
  }

  // Divisions

  const [getDivisions] = useGetDivisionsLazyQuery();
  const [createDivisionMutation] = useCreateDivisionMutation();

  const fetchExistingDivisions = async (divisions: DivisionCreateInput[]): Promise<Division[] | undefined> => {
    try {
      const data = await getDivisions({
        variables: {
          first: divisions.length,
          condition: {
            or: divisions.map((division) => ({
              name: division.name,
              businessUnitId: division.businessUnitId,
            } as DivisionCondition)),
          }
        },
        fetchPolicy: 'network-only',
      });
      data.data?.divisions.nodes.forEach((division) => {
        dispatch(addExisting({
          type: 'division',
          data: division,
        }));
      });
      return data.data?.divisions.nodes as Division[];
    } catch (error) {
      console.error(error);
    }
  };

  const lookUpExistingDivisions = async (): Promise<void> => {
    console.log('lookUpExistingDivisions');
    const divisions: DivisionCreateInput[] = [];
    importState.parsedData.divisions.forEach((division) => {
      const buName = division.businessUnitId;
      const buId = findBusinessUnitId(buName);

      if (buId) {
        divisions.push({
          ...division,
          businessUnitId: buId,
        });
      } else {
        dispatch(addFailed({
          type: 'division',
          data: division,
          error: `Business Unit ${buName} not found for division ${division.name}`,
        }));
      }
    });

    const chunks = chunk(divisions, 50);
    const existingDivisions: Division[] = [];

    for (let i = 0; i < chunks.length; i++) {
      try {
        const items = await fetchExistingDivisions(chunks[i]);
        if (items) {
          existingDivisions.push(...items);
        }
      } catch (error) {
        console.error(error);
      }
    }

    // Save missing divisions to state
    divisions.forEach((division) => {
      const index = existingDivisions.findIndex(value => {
        return value.name === division.name && value.businessUnitId === division.businessUnitId;
      });
      if (index === -1) {
        dispatch(addMissing({
          type: 'division',
          data: division,
        }));
      }
    });

    setDivisionState({lookup: true, lookupStarted: true, import: false, importStarted: false});
  };

  const importDivisions = async (): Promise<void> => {
    console.log('importDivisions');
    const missing = importState.status.division.missing;
    for (let i = 0; i < missing.length; i++) {
      const input: DivisionCreateInput = {
        ...(missing[i] as DivisionCreateInput),
      };

      try {
        const resp = await createDivisionMutation({
          variables: {
            input: input,
          }
        });

        if (resp.data?.createDivision) {
          dispatch(addComplete({
            type: 'division',
            data: resp.data.createDivision,
          }));
        } else if (resp.errors?.length) {
          dispatch(addFailed({
            type: 'division',
            data: input,
            error: resp.errors[0].message,
          }));
        }
      } catch (error) {
        dispatch(addFailed({
          type: 'division',
          data: input,
          error: (error as ApolloError).message,
        }));
      }
    }

    setDivisionState({lookup: true, lookupStarted: true, import: true, importStarted: true});
    dispatch(setDone({
      type: 'division',
      done: true,
    }));
  };

  function findDivisionId(buId: string | undefined, name: string | undefined): string | undefined {
    if (buId && name) {
      const divisions: Division[] = [...importState.status.division.existing, ...importState.status.division.complete];
      const obj = divisions.find((x: Division) => x.name === name && x.businessUnitId === buId);
      if (obj) {
        return obj.id;
      }
    }
    return undefined;
  }

  // Employees

  const [getEmployees] = useGetEmployeesLazyQuery();
  const [createEmployeeMutation] = useCreateEmployeeMutation();

  const fetchExistingEmployees = async (employees: EmployeeCreateInput[]): Promise<Employee[] | undefined> => {
    try {
      const data = await getEmployees({
        variables: {
          first: employees.length,
          condition: {
            or: employees.map((employee) => ({
              email: employee.email
            })),
          }
        },
        fetchPolicy: 'network-only',
      });
      data.data?.employees?.nodes.forEach((employee) => {
        dispatch(addExisting({
          type: 'employee',
          data: employee,
        }));
      });
      return data.data?.employees?.nodes as Employee[];
    } catch (error) {
      console.error(error);
    }
  };

  const lookUpExistingEmployees = async (): Promise<void> => {
    console.log('lookUpExistingEmployees');
    const chunks = chunk(importState.parsedData.employees, 50);
    const existingEmployees: Employee[] = [];

    for (let i = 0; i < chunks.length; i++) {
      try {
        const items = await fetchExistingEmployees(chunks[i]);
        if (items) {
          existingEmployees.push(...items);
        }
      } catch (error) {
        console.error(error);
      }
    }

    // Save missing employees to state
    importState.parsedData.employees.forEach((employee) => {
      const index = existingEmployees.findIndex(value => {
        return value.email === employee.email;
      });
      if (index === -1) {
        dispatch(addMissing({
          type: 'employee',
          data: employee,
        }));
      }
    });

    console.log('lookUpExistingEmployees done');
    setEmployeeState({lookup: true, lookupStarted: true, import: false, importStarted: false});
  };

  const importEmployees = async (): Promise<void> => {
    console.log('importEmployees');
    const missing = importState.status.employee.missing;
    for (let i = 0; i < missing.length; i++) {
      const input: EmployeeCreateInput = {
        ...(missing[i] as EmployeeCreateInput),
      };

      // Lookup the business unit ids
      const row = importState.rowData.find((r) => {
        return r.employee.email === input.email && r.location.name && r.businessUnit.name && r.division.name;
      });

      const locationId = findLocId(row?.location.name);
      const businessUnitId = findBusinessUnitId(row?.businessUnit.name);
      const divisionId = findDivisionId(businessUnitId, row?.division.name);

      if (!locationId) {
        dispatch(addFailed({
          type: 'employee',
          data: input,
          error: `Location ${row?.location.name} not found`,
        }));
        continue;
      }
      if (!businessUnitId) {
        dispatch(addFailed({
          type: 'employee',
          data: input,
          error: `Business Unit ${row?.businessUnit.name} not found`,
        }));
        continue;
      }
      if (!divisionId) {
        dispatch(addFailed({
          type: 'employee',
          data: input,
          error: `Division ${row?.division.name} not found`,
        }));
        continue;
      }

      input.locationId = locationId;
      input.businessUnitId = businessUnitId;
      input.divisionId = divisionId;

      try {
        const resp = await createEmployeeMutation({
          variables: {
            input: input,
          }
        });

        if (resp.data?.createEmployee) {
          dispatch(addComplete({
            type: 'employee',
            data: resp.data.createEmployee,
          }));
        } else if (resp.errors?.length) {
          dispatch(addFailed({
            type: 'employee',
            data: input,
            error: resp.errors[0].message,
          }));
        }
      } catch (error) {
        dispatch(addFailed({
          type: 'employee',
          data: input,
          error: (error as ApolloError).message,
        }));
      }
    }

    console.log('importEmployees done');
    setEmployeeState({lookup: true, lookupStarted: true, import: true, importStarted: true});
    dispatch(setDone({
      type: 'employee',
      done: true,
    }));
  };

  function findEmployeeId(email: string): string | undefined {
    if (email) {
      const employees: Employee[] = [...importState.status.employee.existing, ...importState.status.employee.complete];
      const obj = employees.find((loc: Employee) => loc.email === email);
      if (obj) {
        return obj.id;
      }
    }
    return undefined;
  }

  const [getEmployeeByEmail] = useGetEmployeeByEmailLazyQuery();
  const [updateEmployeeAssociation] = useUpdateEmployeeAssociationMutation();

  const lookUpManager = async (email: string): Promise<Employee | undefined> => {
    try {
      const value = await getEmployeeByEmail({
        variables: {
          email: email,
        }
      });
      return value.data?.employeeByEmail as Employee;
    } catch (err) {
      console.error(`Manager ${email} not found`);
    }
    return undefined;
  };

  const associateEmployeeManagers = async (): Promise<void> => {
    console.log('start associateEmployeeManagers');
    for (const employee of importState.status.employee.complete as Employee[]) {
      const managerEmail = importState.rowData.find((row) => (
        row.employee && row.employee.email === employee.email && row.employee.managerEmail
      ))?.employee.managerEmail;
      if (managerEmail && managerEmail !== employee.email && managerEmail !== '' && managerEmail !== ' ') {

        let managerId = findEmployeeId(managerEmail);
        if (!managerId) {
          const manager = await lookUpManager(managerEmail);
          if (manager) {
            managerId = manager.id;
          }
        }
        if (managerId && managerId != employee.managerId) {
          try {
            await updateEmployeeAssociation({
              variables: {
                id: employee.id,
                input: {
                  managerId: managerId,
                }
              }
            });
          } catch (err) {
            console.error(`error updating manager for Employee ${employee.email}`);
          }
        } else {
          console.error(`Manager ${managerEmail} not found`);
          dispatch(addError({
            type: 'employee',
            error: `Manager ${managerEmail} not found`,
          }));
        }
      }
    }

    setEmployeeManagerState({lookup: true, lookupStarted: true, import: true, importStarted: true});
  };

  // Schedules

  const parseScheduleData = async (): Promise<void> => {
    const schedules: ScheduleCreateInput[] = [];

    for (let i = 0; i < importState.rowData.length; i++) {
      const row = importState.rowData[i];
      if (row.employee && row.employee.email && row.schedule && row.schedule.start) {
        const employeeId = findEmployeeId(row.employee.email);
        if (employeeId) {
          const schedule = parseSchedule(employeeId, row);
          if (schedule) {
            schedules.push(schedule);
          }
        } else {
          // If the employee is not found, then lets query the employee by email
          // and try again
          const data = await getEmployeeByEmail({
            variables: {
              email: row.employee.email,
            },
            fetchPolicy: 'network-only',
          });
          const employee = data.data?.employeeByEmail as Employee;
          if (employee) {
            const schedule = parseSchedule(employee.id, row);
            if (schedule) {
              schedules.push(schedule);
            }
          }
        }
      }
    }
    dispatch(setParsedData({
      ...importState.parsedData,
      schedules: schedules,
    }));
    setScheduleParseState({lookup: true, lookupStarted: true, import: true, importStarted: true});
  };

  const [getSchedules] = useGetSchedulesLazyQuery();
  const [createScheduleMutation] = useCreateScheduleEntryMutation();

  const fetchExistingSchedules = async (schedules: ScheduleCreateInput[]): Promise<Schedule[] | undefined> => {
    try {
      const data = await getSchedules({
        variables: {
          first: schedules.length,
          condition: {
            or: schedules.map((schedule) => ({
              employeeId: schedule.employeeId,
              type: schedule.type,
              start: schedule.start,
              end: schedule.end,
            })),
          }
        },
        fetchPolicy: 'network-only',
      });
      data.data?.schedules.nodes.forEach((schedule) => {
        dispatch(addExisting({
          type: 'schedule',
          data: schedule,
        }));
      });
      return data.data?.schedules.nodes as Schedule[];
    } catch (error) {
      console.error(error);
    }
  };

  const lookUpExistingSchedules = async (): Promise<void> => {
    console.log('lookUpExistingSchedules');
    const chunks = chunk(importState.parsedData.schedules, 50);
    const existingSchedules: Schedule[] = [];

    for (let i = 0; i < chunks.length; i++) {
      try {
        const items = await fetchExistingSchedules(chunks[i]);
        if (items) {
          existingSchedules.push(...items);
        }
      } catch (error) {
        console.error(error);
      }
    }

    // Save missing schedules to state
    importState.parsedData.schedules.forEach((schedule) => {
      const index = existingSchedules.findIndex(value => {
        return (
          value.employeeId === schedule.employeeId &&
          value.type === schedule.type &&
          value.start === schedule.start &&
          value.end === schedule.end
        );
      });
      if (index === -1) {
        dispatch(addMissing({
          type: 'schedule',
          data: schedule,
        }));
      }
    });

    setScheduleState({lookup: true, lookupStarted: true, import: false, importStarted: false});
  };

  const importSchedules = async (): Promise<void> => {
    console.log('importSchedules');
    const missing = importState.status.schedule.missing;
    for (let i = 0; i < missing.length; i++) {
      const input: ScheduleCreateInput = missing[i];

      try {
        const resp = await createScheduleMutation({
          variables: {
            input: input,
          }
        });

        if (resp.data?.createSchedule) {
          dispatch(addComplete({
            type: 'schedule',
            data: resp.data.createSchedule,
          }));
        } else if (resp.errors?.length) {
          dispatch(addFailed({
            type: 'schedule',
            data: input,
            error: resp.errors[0].message,
          }));
        }
      } catch (error) {
        dispatch(addFailed({
          type: 'schedule',
          data: input,
          error: (error as ApolloError).message,
        }));
      }
    }

    setScheduleState({lookup: true, lookupStarted: true, import: true, importStarted: true});
    dispatch(setDone({type: 'schedule', done: true}));
  };

  return {
    uploadFile, parseData, importData, reviewImport,
    lookUpExistingLocations, importLocations,
    lookUpExistingBusinessUnits, importBusinessUnits,
    lookUpExistingDivisions, importDivisions,
    lookUpExistingEmployees, importEmployees, associateEmployeeManagers,
    lookUpExistingSchedules, importSchedules,
  };
};

export default useWorkforceImport;