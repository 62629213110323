import Grid from '@mui/material/Unstable_Grid2';
import {Container} from '@mui/material';
import {useUserClaims} from '../../context/UserClaimsContext';
import {StatusCard} from '../../components/common/StatusCard';

export function HomePage() {
  const {authUserClaims} = useUserClaims();

  return (<>
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center"
      sx={{pt: 1, marginBottom: 2}}>
      <h1>Welcome, {authUserClaims.userClaims?.userInfo?.name}!</h1>
    </Grid>
    <Container sx={{width: '50%'}}>
      <StatusCard></StatusCard>
    </Container>
  </>);
}