import {useEffect, useState} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {FormControlLabel, Switch, TextField} from '@mui/material';
import {Maybe, ProxyTest} from '../../../../generated/graphql';
import {ValidateProxyUri, ValidTextField} from '../../../../components/common/form/ValidTextField';

interface ProxyTargetViewProps {
  value?: Maybe<ProxyTest>,
  onUpdate: (value: ProxyTest | null) => void,
}

export function ProxyTargetView({value, onUpdate}: ProxyTargetViewProps) {
  const [enabled, setEnabled] = useState<boolean>(!value);
  const [address, setAddress] = useState<string>(value?.address || '');
  const [priority, setPriority] = useState<number>(value?.priority || 1000);

  useEffect(() => {
    if (!value) {
      setEnabled(false);
      onUpdate(null);
    } else {
      setEnabled(true);
      setAddress(value.address);
      setPriority(value.priority);
    }
  }, [value]);

  return (
    <Grid container margin={1}>
      <Grid xs={2}>
        <FormControlLabel label={'Proxy'} sx={{alignItems: 'center', margin: 1}}
          control={<Switch checked={enabled} onChange={(e) => {
            setEnabled(e.target.checked);
          }}/>}/>
      </Grid>
      <Grid xs={2}>
        <ValidTextField required={enabled} value={address}
          setValue={setAddress} validate={ValidateProxyUri}
          label="URI" disabled={!enabled}/>
      </Grid>
      <Grid xs={2}>
        <TextField value={priority} label="Priority" type={'number'} disabled={!enabled}
          onChange={(e) => {
            setPriority(parseInt(e.target.value));
          }}/>
      </Grid>
    </Grid>
  );
}