import React from 'react';
import {Navigate, Outlet, RouteObject} from 'react-router-dom';
import {store} from './store';
import {LoginPage} from './views/login/LoginPage';
import {UserInfo} from './views/UserInfo';
import {SiteWrapper} from './components/SiteWrapper';
import {NavBar} from './components/nav-bar/NavBar';
import {HomePage} from './views/home/HomePage';
import {WorkforceImport} from './views/workforce-import/WorkforceImport';
import {WorkforceManage} from './views/workforce-manage/WorkforceManage';
import {GlobalSnackbar} from './features/snackbar/GlobalSnackbar';

export const ProtectedLayout = () => {
  const state = store.getState();
  return (
    <SiteWrapper>
      <NavBar/>
      {state.auth.isAuthenticated ? <Outlet/> : <LoginPage/>}
      <GlobalSnackbar/>
    </SiteWrapper>
  );
};

export const NavBarEntries = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Manage',
    path: '/manage',
  },
  {
    label: 'Workforce Import',
    path: '/import',
  },
];

export const routes: RouteObject[] = [{
  path: '/',
  Component: ProtectedLayout,
  children: [
    {
      path: '/',
      element: <HomePage/>
    },
    {
      path: '*',
      element: <Navigate replace to="/not-found-cover"/>
    },
    {
      path: 'user',
      element: <UserInfo/>
    },
    {
      path: 'import',
      element: <WorkforceImport/>
    },
    {
      path: 'manage',
      element: <WorkforceManage/>
    }
  ],
}];

export default routes;
