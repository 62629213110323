import {Alert, AlertTitle} from '@mui/material';
import {useI18nContext} from '../../hooks/useI18n';

export interface ApiErrorAlertProps {
  title?: string;
  possibleErrors?: string[];
}

/**
 * Standard alert for an API call failure
 */
const ApiErrorAlert = (props: ApiErrorAlertProps) => {
  const {translate} = useI18nContext();
  const possibleErrors = props.possibleErrors || [
    translate('error_possibility_unavailable_api'),
    translate('error_possibility_unauthorized')
  ];

  return (
    <Alert severity="error">
      <AlertTitle>{props.title || translate('default_error_message')}</AlertTitle>
      <ul>
        {possibleErrors.map((errMsg, idx) => (
          <li key={idx}>{errMsg}</li>
        ))}
      </ul>
    </Alert>
  );
};

export default ApiErrorAlert;
