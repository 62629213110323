import {BusinessUnit} from '../../../generated/graphql';
import {Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {useState} from 'react';
import {DivTable} from './DivTable';
import {BuTable} from './BuTable';

export function BusinessUnitList() {
  const [selectedBU, setSelectedBU] = useState<BusinessUnit | null>(null);
  return (
    <Grid container alignItems="center" justifyContent="center" spacing={2} overflow={'scroll'}>
      <Grid xs={6} sx={{alignSelf: 'start'}}>
        <Typography variant="h5" component="h2" gutterBottom>
          Business Units
        </Typography>
        <BuTable handleRowSelection={(_, selected) => {
          if (selected.length === 0) {
            setSelectedBU(null);
            return;
          }
          setSelectedBU(selected[0]);
        }}/>
      </Grid>
      <Grid xs={6} sx={{alignSelf: 'start'}}>
        <Typography variant="h5" component="h2" gutterBottom>
          Divisions
        </Typography>
        <DivTable businessUnit={selectedBU}/>
      </Grid>
    </Grid>
  );
}
