import {Employee, useGetEmployeesLazyQuery} from '../../../generated/graphql';
import {useEffect, useState} from 'react';
import {SelectProps} from '@mui/material';

import {ValidatedSelect} from '../../../components/common/form/ValidatedSelect';
import {fetchEmployees} from './EmployeeTable';

type ManagerDropdownProps = {
  variant?: 'standard' | 'filled' | 'outlined',
  setValue: (value: (string)) => void,
  validate: (value: string) => boolean,
  value: string,
}

export function ManagerDropdown({
  variant = 'outlined',
  setValue, validate, value,
  ...props
}: ManagerDropdownProps & Omit<SelectProps, 'variant' | 'value' | 'onChange'>) {
  const [values, setValues] = useState<Employee[] | undefined>(undefined);
  const [getEmployees, {loading, error}] = useGetEmployeesLazyQuery({
    // fetchPolicy: 'network-only',
    fetchPolicy: 'no-cache',
    // nextFetchPolicy: 'cache-first',
  });
  const fetchData = fetchEmployees(getEmployees, 50, setValues);

  useEffect(() => {
    fetchData().then();
  }, []);

  return <ValidatedSelect
    onChange={setValue}
    values={values}
    validate={validate}
    variant={variant}
    disabled={!!(error || loading || !values)}
    value={value}
    {...props} />;
}