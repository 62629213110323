import {useState} from 'react';
import {GridRowSelectionModel} from '@mui/x-data-grid/models/gridRowSelectionModel';
import {DataGrid, GridColDef, GridToolbarContainer} from '@mui/x-data-grid';
import {Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface ArrayCrudViewProps<T> {
  columns: GridColDef[],
  rows: Array<T>,
  onUpdate: (value: (((prevState: Array<T>) => Array<T>) | Array<T>)) => void,
  newValue: () => T
}

export function ArrayCrudView<T>({columns, rows, onUpdate, newValue}: ArrayCrudViewProps<T>) {
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          startIcon={<AddIcon/>}
          onClick={() => {
            onUpdate((prev) => [...prev, newValue()]);
          }}
        >
          Add
        </Button>
        <Button
          color="secondary" disabled={selected.length === 0}
          onClick={() => {
            onUpdate((prev) => prev.filter((_, index) => !selected.includes(index)));
            setSelected([]);
          }}
        >
          Delete
        </Button>
      </GridToolbarContainer>
    );
  }

  return (<>
    <DataGrid columns={columns} rows={rows}
      getRowId={(row) => rows.indexOf(row)}
      processRowUpdate={(newRow, oldRow) => {
        const newRows = rows.slice();
        const i = rows.indexOf(oldRow);
        newRows[i] = newRow;
        onUpdate(newRows);
        return newRows;
      }}
      slots={{
        toolbar: EditToolbar
      }}
      onRowSelectionModelChange={setSelected}
      disableColumnSelector
    />
  </>);
}