import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridPaginationModel,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel
} from '@mui/x-data-grid';
import {GridRowSelectionModel} from '@mui/x-data-grid/models/gridRowSelectionModel';
import {Node} from '../../generated/graphql';
import {useState} from 'react';
import {Box} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {UncapitalizeObjectKeys} from '@mui/x-data-grid/internals/utils';
import {GridSlotsComponent} from '@mui/x-data-grid/models/gridSlotsComponent';

interface FullFeaturedCrudGridProps<T extends Node> {
  rows: GridRowModel[],
  columns: GridColDef[],
  processRowUpdate: (newRow: GridRowModel) => Promise<GridRowModel>,
  handleDeleteClick: (id: GridRowId) => void,
  handleRowSelection?: (rowSelectionModel: GridRowSelectionModel, selected: T[]) => void,
  loading?: boolean,
  slots?: UncapitalizeObjectKeys<Partial<GridSlotsComponent>>
  onPaginationModelChange?: (pagination: GridPaginationModel) => void,
  disableEdit?: boolean,
}

export function FullFeaturedCrudGrid<T extends Node>(props: FullFeaturedCrudGridProps<T>) {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    // console.log('handleRowModesModelChange', newRowModesModel);
    setRowModesModel(newRowModesModel);
  };

  const handleEditClick = (id: GridRowId) => () => {
    // console.log('handleEditClick', rowModesModel[id]);
    setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: {mode: GridRowModes.View},
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    // console.log('handleCancelClick', rowModesModel[id]);
    setRowModesModel({
      ...rowModesModel,
      [id]: {mode: GridRowModes.View, ignoreModifications: true},
    });
  };

  const gridActions = (
    id: string | number,
    handleDeleteClick: (id: GridRowId) => void,
  ) => {
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    if (isInEditMode) {
      return [
        <GridActionsCellItem
          key={`save-${id}`}
          icon={<SaveIcon/>}
          label="Save"
          sx={{color: 'primary.main'}}
          onClick={handleSaveClick(id)}
        />,
        <GridActionsCellItem
          key={`cancel-${id}`}
          icon={<CancelIcon/>}
          label="Cancel"
          className="textPrimary"
          onClick={handleCancelClick(id)}
          color="inherit"
        />,
      ];
    }

    return [
      props.disableEdit === true ? <></> : <GridActionsCellItem
        key={`edit-${id}`}
        icon={<EditIcon/>}
        label="Edit"
        className="textPrimary"
        onClick={handleEditClick(id)}
        color="inherit"
      />,
      <GridActionsCellItem
        key={`delete-${id}`}
        icon={<DeleteIcon/>}
        label="Delete"
        onClick={() => handleDeleteClick(id)}
        color="inherit"
      />,
    ];
  };

  const getActionColumn: GridColDef = {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    align: 'right',
    headerAlign: 'right',
    getActions: (params) => gridActions(params.row.id, props.handleDeleteClick),
    // flex: 1,
  };

  return (
    <Box sx={{
      width: '100%',
      '& .actions': {
        color: 'text.secondary',
      },
      '& .textPrimary': {
        color: 'text.primary',
      },
    }}>
      <DataGrid
        sx={{
          height: '100%',
          minHeight: 200,
        }}
        rows={props.rows}
        columns={[...props.columns, getActionColumn]}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={props.processRowUpdate}
        onRowSelectionModelChange={(newSelection) => {
          const selected = props.rows.filter((row) => newSelection.includes(row.id));
          if (props.handleRowSelection) {
            props.handleRowSelection(newSelection, selected as T[]);
          }
        }}

        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5, 10, 15, 20]}
        onPaginationModelChange={(pagination) => {
          if (props.onPaginationModelChange) {
            props.onPaginationModelChange(pagination);
          }
        }}
        disableRowSelectionOnClick={props.handleRowSelection === undefined}
        disableColumnSelector
        loading={props.loading}
        slots={props.slots}
        // slotProps={{
        //   toolbar: {setRows, setRowModesModel},
        // }}
      />
    </Box>
  );
}