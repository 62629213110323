import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {AlertColor} from '@mui/material/Alert';

export interface SnackbarMessage {
  id: string,
  message: string,
  severity: AlertColor,
  autoHideDuration?: number | null,
}

export interface SnackbarState {
  messages: SnackbarMessage[],
}

const initialState: SnackbarState = {
  messages: [],
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    closeMessage: (state, action) => {
      state.messages = state.messages.filter((message) => message.id !== action.payload);
    },
    clearMessages: (state) => {
      state.messages = [];
    }
  },
});

export const {addMessage, closeMessage, clearMessages} = snackbarSlice.actions;
export default snackbarSlice.reducer;

export const selectSnackbar = (state: RootState) => state.snackbar;