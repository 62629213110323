import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {Chip, FormControlLabel, Switch, TextField} from '@mui/material';
import {BasicTestV2} from '../../../../generated/graphql';

interface WifiV2TargetViewProps {
  defaultCrontab: string,
  value: BasicTestV2 | null | undefined,
  onUpdate: (value: BasicTestV2 | null) => void,
}

export function WifiV2TargetView({defaultCrontab, value, onUpdate}: WifiV2TargetViewProps) {
  const [enabled, setEnabled] = useState<boolean>(!!value);
  const [cronTrigger, setCronTrigger] = useState<string>(value?.cronTrigger || defaultCrontab);
  const [priority, setPriority] = useState<number>(value?.priority || 1000);

  useEffect(() => {
    if (value) {
      setEnabled(true);
      setCronTrigger(value.cronTrigger || defaultCrontab);
      setPriority(value.priority);
    } else {
      setEnabled(false);
      setCronTrigger(defaultCrontab);
      setPriority(1000);
    }
  }, [value]);

  useEffect(() => {
    if (enabled) {
      onUpdate({
        name: 'wifi',
        bypassCache: false,
        cronTrigger,
        priority,
        tolerance: 0,
      });
    } else {
      onUpdate(null);
    }
  }, [enabled, cronTrigger, priority]);

  return (
    <Grid container margin={1}>
      <Grid xs={2}>
        <FormControlLabel label={<>Wifi <Chip sx={{marginLeft: 1}} label={'v2'} size={'small'}/></>} sx={{alignItems: 'center', margin: 1}}
          control={<Switch checked={enabled} onChange={(e) => {
            setEnabled(e.target.checked);
          }}/>}/>
      </Grid>
      <Grid xs={2}>
        <TextField value={cronTrigger} label="Cron" disabled={!enabled}
          onChange={(e) => {
            setCronTrigger(e.target.value);
          }}/>
      </Grid>
      <Grid xs={2}>
        <TextField value={priority} label="Priority" type={'number'} disabled={!enabled}
          onChange={(e) => {
            setPriority(parseInt(e.target.value));
          }}/>
      </Grid>
    </Grid>
  );
}