export interface Locale {
  code: string;
  language: string;
}

const supportedLocales = ['en-US', 'es-ES'];

export const defaultLocale = {code: 'en-US', language: 'en'};

export const getBrowserLocales = (): Locale[] => {
  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;
  if (!browserLocales) {
    return [];
  }
  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    return {
      code: trimmedLocale,
      language: trimmedLocale.split(/-|_/)[0]
    };
  });
};

export const getSupportedBrowserLocales = (): Locale[] => {
  return getBrowserLocales().filter((locale) => supportedLocales.indexOf(locale.code) >= 0);
};
