import {AppBar, Box, Tab, Tabs, Toolbar} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {UserButtons} from './UserButtons';
import {matchPath, NavLink, useLocation} from 'react-router-dom';
import {NavBarEntries} from '../../Routes';

function useRouteMatch(patterns: readonly string[]) {
  const {pathname} = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export function NavBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  // const authenticated = isAuthenticated(store.getState());

  const routeMatch = useRouteMatch(['/', '/manage', '/import']);
  const currentTab = routeMatch?.pattern?.path;


  const getLogo = () => {
    return isMobile ? (
      <img src="/img/gear-white.svg" width={50} alt="Truiem Logo"/>
    ) : (
      <img src="/img/logo-white.png" width={250} alt="Truiem Logo"/>
    );
  };

  return (
    <AppBar position="static" sx={{zIndex: (theme) => theme.zIndex.drawer + 1, flexGrow: 1}}>
      <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Box sx={{display: 'flex', flexGrow: 0}}>
          <NavLink to={'/'}>{getLogo()}</NavLink>
        </Box>

        <Tabs value={currentTab} sx={{display: 'flex', flexGrow: 0, marginLeft: 2, justifySelf: 'center'}}
          textColor="inherit" indicatorColor="secondary">
          {NavBarEntries.map((entry) => (
            <Tab key={entry.path} label={entry.label} value={entry.path} to={entry.path} component={NavLink}/>
          ))}
        </Tabs>

        <Box sx={{width: '250px', display: {xs: 'none', md: 'flex'}, justifyContent: 'end'}}>
          <UserButtons/>
        </Box>
        <Box sx={{display: {xs: 'flex', md: 'none'}, justifyContent: 'end'}}>
          <UserButtons/>
        </Box>
      </Toolbar>
    </AppBar>
  );
}