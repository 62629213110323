import {
  EmployeeAccountCreateInput,
  EmployeeAccountType,
  useAssociateEmployeeAccountMutation,
  useGetEmployeeAttributesLazyQuery,
  useRemoveEmployeeAccountMutation
} from '../../../generated/graphql';
import React, {useEffect, useState} from 'react';
import {Box, Button, MenuItem, Modal, Select, Typography} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import {ValidateRequiredString, ValidTextField} from '../../../components/common/form/ValidTextField';
import {addMessage} from '../../../features/snackbar/snackbarSlice';
import {v4 as uuidv4} from 'uuid';
import {useAppDispatch} from '../../../hooks/app';
import {DataGrid, GridActionsCellItem, GridColDef} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

interface EditEmployeeAccountModalProps {
  open: boolean,
  employeeId: string,
  handleClose: () => void,
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

const sharedOptions: Partial<GridColDef> = {
  flex: 1,
};
const accountColumns: GridColDef[] = [
  {
    field: 'type',
    headerName: 'Type',
    ...sharedOptions,
  },
  {
    field: 'domain',
    headerName: 'Domain',
    ...sharedOptions,
  },
  {
    field: 'login',
    headerName: 'Login',
    ...sharedOptions,
  },
];

const EditEmployeeAccountModal = ({open, employeeId, handleClose}: EditEmployeeAccountModalProps) => {
  const dispatch = useAppDispatch();
  const [associateEmployeeAccountMutation] = useAssociateEmployeeAccountMutation();
  const [removeEmployeeAccountMutation] = useRemoveEmployeeAccountMutation();
  const [accounts, setAccounts] = useState<EmployeeAccountCreateInput[]>([]);
  const [getEmployee] = useGetEmployeeAttributesLazyQuery({
    variables: {
      id: employeeId,
    },
    fetchPolicy: 'network-only',
  });
  const [newAccountValues, setNewAccountValues] = useState<EmployeeAccountCreateInput>({
    type: EmployeeAccountType.SamAccountName,
    domain: '',
    login: '',
  });

  const refreshAccounts = () => {
    // console.log('refreshAccounts', employeeId);
    getEmployee({
      variables: {
        id: employeeId,
      }
    })
      .then((result) => {
        // console.log('refreshAccounts', result);
        if (result.data) {
          setAccounts(result.data?.employee?.accounts || []);
        } else {
          setAccounts([]);
        }
      })
      .catch((error) => {
        dispatch(addMessage({
          id: uuidv4(),
          message: error.message,
          severity: 'error',
          autoHideDuration: 3000,
        }));
      });
  };

  const handleAssociateEmployeeAccount = async (employeeId: string, accountInfo: EmployeeAccountCreateInput) => {
    await associateEmployeeAccountMutation({
      variables: {
        id: employeeId,
        input: accountInfo,
      }
    });
  };

  const handleRemoveEmployeeAccount = async (employeeId: string, accountInfo: EmployeeAccountCreateInput) => {
    await removeEmployeeAccountMutation({
      variables: {
        id: employeeId,
        input: {
          type: accountInfo.type,
          domain: accountInfo.domain,
          login: accountInfo.login,
        },
      }
    });
  };

  useEffect(() => {
    refreshAccounts();
  }, [employeeId]);

  const gridActions = (
    id: string | number,
    row: EmployeeAccountCreateInput
  ) => {
    return [
      <GridActionsCellItem
        key={`delete-${id}`}
        icon={<DeleteIcon/>}
        label="Delete"
        onClick={() => {
          handleRemoveEmployeeAccount(employeeId, row)
            .then(() => {
              refreshAccounts();
            })
            .catch((error) => {
              dispatch(addMessage({
                id: uuidv4(),
                message: error.message,
                severity: 'error',
                autoHideDuration: 3000,
              }));
            });
        }}
        color="inherit"
      />,
    ];
  };

  const getActionColumn: GridColDef = {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    align: 'right',
    headerAlign: 'right',
    getActions: (params) => gridActions(params.row.id, params.row as EmployeeAccountCreateInput),
    // flex: 1,
  };

  return (
    <Modal open={open} onClose={handleClose}
      aria-labelledby="create-new-employee"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Edit Employee Accounts
        </Typography>

        <DataGrid
          columns={[...accountColumns, getActionColumn]} rows={accounts}
          getRowId={(row) => {
            return `${row.type}-${row.domain}-${row.login}`;
          }}
        />

        <Box>
          <EmployeeAccountForm account={newAccountValues} onUpdate={setNewAccountValues}/>
          <Button sx={{marginTop: 2}} variant="contained" onClick={() => {
            handleAssociateEmployeeAccount(employeeId, newAccountValues)
              .then(() => {
                setNewAccountValues({
                  type: EmployeeAccountType.SamAccountName,
                  domain: '',
                  login: '',
                });
                refreshAccounts();
              })
              .catch((error) => {
                dispatch(addMessage({
                  id: uuidv4(),
                  message: error.message,
                  severity: 'error',
                  autoHideDuration: 3000,
                }));
              });
          }}>
            Add
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditEmployeeAccountModal;

interface EmployeeAccountFormProps {
  account: EmployeeAccountCreateInput,
  onUpdate: (account: EmployeeAccountCreateInput, valid: boolean) => void,
}

const EmployeeAccountForm = ({
  account, onUpdate,
}: EmployeeAccountFormProps) => {

  const [type, setType] = useState(account.type);
  const [domain, setDomain] = useState(account.domain);
  const [login, setLogin] = useState(account.login);

  useEffect(() => {
    setType(account.type);
    setDomain(account.domain);
    setLogin(account.login);
  }, [account]);


  useEffect(() => {
    onUpdate({type, domain, login}, ValidateRequiredString(domain) && ValidateRequiredString(login));
  }, [type, domain, login]);

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={4} md={4}>
        <Select defaultValue={EmployeeAccountType.SamAccountName}
          onChange={(e) => {
            const value = e.target.value;
            setType(value as EmployeeAccountType);
          }}>
          <MenuItem value={EmployeeAccountType.SamAccountName}>SamAccountName</MenuItem>
          <MenuItem value={EmployeeAccountType.UserPrincipalName}>UserPrincipalName</MenuItem>
        </Select>
      </Grid2>
      <Grid2 xs={4} md={4}>
        <ValidTextField required fullWidth sx={{maxWidth: 300}}
          label="Domain" value={domain} setValue={setDomain}
          validate={ValidateRequiredString}
        />
      </Grid2>
      <Grid2 xs={4} md={4}>
        <ValidTextField required fullWidth sx={{maxWidth: 300}}
          label="Login" value={login} setValue={setLogin}
          validate={ValidateRequiredString}
        />
      </Grid2>
    </Grid2>
  );
};