/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Box, Paper, Tab, Tabs} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../../hooks/app';
import {selectWorkforceImport, setCurrentStep} from '../../../features/workforceImport/workforceImportSlice';
import DualColumnComponent from './validate/DualColumnComponent';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import {SourceColumnDraggable} from './validate/SourceColumnDraggable';
import LoadingButton from '@mui/lab/LoadingButton';
import ApiErrorAlert from '../../../components/common/ApiErrorAlert';
import useWorkforceImport from '../../../features/workforceImport/useWorkforceImport';
import {ColumnTypes, RequiredColumns} from '../../../services/import-service';

// TAB Components

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

// Drag and Drop Columns

interface ImportSheetColumnsProps {
  selectedSheet: string | null,
}

function ImportSheetColumns(props: ImportSheetColumnsProps) {
  const workforceImport = useAppSelector(selectWorkforceImport);
  const data = workforceImport.file;

  if (!data || !props.selectedSheet || !data[props.selectedSheet as string]) {
    return <></>;
  }

  const sheetName = props.selectedSheet;
  const columns: string[] = [];

  // Look for missing keys
  data[sheetName].forEach((row: any) => {
    const rowkeys = Object.keys(row);
    rowkeys.forEach((key) => {
      if (columns.indexOf(key) < 0) {
        columns.push(key);
      }
    });
  });

  return (<Box>
    {columns.map((column) => (
      <DualColumnComponent key={`ic-${sheetName}-${column}`} sheet={sheetName} column={column}/>
    ))}
  </Box>);
}

export function DragAndDropMatching() {
  const dispatch = useAppDispatch();
  const {parseData, reviewImport} = useWorkforceImport();

  const importState = useAppSelector(selectWorkforceImport);
  const [sheetNames, setSheetNames] = useState<string[]>([]);
  const [selectedSheet, setSelectedSheet] = useState<number>(0);

  const [validating, setValidating] = useState<boolean>(false);
  const [parseErrors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (importState.file) {
      const workbook = importState.file;
      const sheets = Object.keys(workbook);
      setSheetNames(sheets);
    }
  }, [importState.file]);

  useEffect(() => {
    if (validating) {
      checkValid();
    }
  }, [importState.parsedData]);
  if (!importState.file) {
    return <></>;
  }

  function checkValid() {
    console.log('checkValid');
    if (!importState.parsedData) {
      setErrors(['No data found in file']);
      return;
    }

    const errors: string[] = [];

    importState.parsedData.locations.forEach((location) => {
      RequiredColumns['location'].forEach((column) => {
        // @ts-ignore
        const value = location[column];
        if (!value) {
          errors.push(`Missing required location column "${column}" in data`);
          return;
        } else if (value && value == '') {
          errors.push(`Location column "${column}" cannot be blank`);
          return;
        }
      });
    });

    importState.parsedData.businessUnits.forEach((bu) => {
      RequiredColumns['businessUnit'].forEach((column) => {
        // @ts-ignore
        const value = bu[column];
        if (!value) {
          errors.push(`Missing required businessUnit column "${column}" in data`);
          return;
        } else if (value && value == '') {
          errors.push(`Business Unit column "${column}" cannot be blank`);
          return;
        }
      });
    });

    importState.parsedData.divisions.forEach((division) => {
      RequiredColumns['division'].forEach((column) => {
        // @ts-ignore
        const value = division[column];
        if (!value) {
          errors.push(`Missing required division column "${column}" in data`);
          return;
        } else if (value && value == '') {
          errors.push(`Division column "${column}" cannot be blank`);
          return;
        }
      });
    });

    importState.parsedData.employees.forEach((employee) => {
      RequiredColumns['employee'].forEach((column) => {
        if (column == 'firstName') {
          // console.log(employee.name);
          if (!employee.name) {
            errors.push('Missing required employee column "name" or "firstName" in data');
          }
          return;
        }

        // @ts-ignore
        const value = employee[column];
        if (!value) {
          errors.push(`Missing required employee column "${column}" in data`);
          return;
        } else if (value && value == '') {
          errors.push(`Employee column "${column}" cannot be blank`);
          return;
        }
      });
    });

    setErrors(errors.filter((value, index, self) => {
      return self.indexOf(value) === index;
    }));
    setValidating(false);

    if (errors.length === 0) {
      dispatch(setCurrentStep('review'));
      reviewImport().then().catch(console.error);
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Box display={'flex'} style={{justifyContent: 'space-around', margin: '0 auto'}}>
        <Paper sx={{width: '100%'}} variant={'outlined'}>
          {/*  Place source values */}
          <Box paddingTop={1} paddingBottom={1} display={'flex'} alignContent={'flex-start'}
            sx={{
              bgcolor: 'background.paper',
              height: 700,
              // width: 1000,
            }}>
            <Tabs value={selectedSheet} aria-label="workbook sheet tabs"
              variant={'scrollable'} scrollButtons={'auto'} orientation={'vertical'}
            >
              {sheetNames.map((key, index) => (
                <Tab key={`vertical-tab-${index}`} {...a11yProps(index)}
                  label={key}
                  onClick={() => setSelectedSheet(index)}/>
              ))}
            </Tabs>
            <Box sx={{overflow: 'scroll'}}>
              {sheetNames.map((key, index) => (
                <TabPanel key={`tab-${key}`} value={selectedSheet} index={index}>
                  <ImportSheetColumns selectedSheet={sheetNames[index]}/>
                </TabPanel>
              ))}
            </Box>

            {/*  Place destination values */}
            <Box paddingTop={1} paddingBottom={1} display={'flex'} alignContent={'flex-start'}
              flexWrap={'wrap'} flexDirection={'column'} flexGrow={1} overflow={'scroll'}>
              {Object.keys(ColumnTypes).map((key) => (
                <SourceColumnDraggable key={`dest-${key}`} type={key} columns={ColumnTypes[key]}
                  sheetName={sheetNames[selectedSheet]}/>
              ))}
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box marginTop={2} sx={{display: 'flex', justifyContent: 'center'}}>
        {parseErrors.length > 0 && (<ApiErrorAlert title={''} possibleErrors={parseErrors}/>)}
      </Box>
      <Box marginTop={2} sx={{display: 'flex', justifyContent: 'end'}}>
        <LoadingButton loading={validating} variant={'contained'} color={'primary'}
          onClick={() => {
            setValidating(true);
            parseData()
              .then()
              .catch(console.error)
              .finally();
          }}>
          Validate
        </LoadingButton>
      </Box>
    </DndProvider>
  );
}
