import {format} from 'date-fns';

export function parseDatetime(date: string): Date {
  return new Date(date);
}

export function formatDatetime(date: Date, formatString = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX'): string {
  return format(date, formatString);
}

export function stringToRFC3339Nano(date: string | undefined): string | undefined {
  if (!date || date === '' || !date.match(/^\d{4}-\d{2}-\d{2}/)) {
    return undefined;
  }
  const parsedTime = parseDatetime(date);
  // console.log({date, parsedTime});
  return formatDatetime(parsedTime);
}