import {createTheme} from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif'].join(','),
    h1: {
      fontFamily: ['Helvetica'].join(','),
      display: 'inline-block',
      fontWeight: '600',
      fontSize: '3em'
    },
    h2: {
      fontSize: '1.95em',
      fontFamily: ['MOTOR OIL', 'Montserrat', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif'].join(',')
    },
    h3: {
      fontSize: '1.25em',
      fontFamily: ['MOTOR OIL', 'Montserrat', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif'].join(',')
    },
    h4: {
      fontFamily: ['MOTOR OIL', 'Montserrat', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif'].join(',')
    },
    h5: {
      fontFamily: ['MOTOR OIL', 'Montserrat', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif'].join(',')
    },
    h6: {
      fontFamily: ['MOTOR OIL', 'Montserrat', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif'].join(',')
    }
  },
  palette: {
    primary: {
      main: '#0B4B76',
      contrastText: '#ffffff',
      light: '#257DC2',
      dark: '#0B4B76'
    },
    secondary: {
      main: '#257DC2',
      contrastText: '#ffffff'
    },
    success: {
      main: '#228B22'
    },
    info: {
      main: '#939597'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#0B4B76'
        },
        colorSecondary: {
          backgroundColor: '#939597'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 8,
          minHeight: 8
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {style: {textTransform: 'none'}}
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: true
      }
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true
      }
    }
  }
});

export default theme;
