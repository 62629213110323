import {useAppDispatch, useAppSelector} from '../../hooks/app';
import {reset, selectWorkforceImport} from '../../features/workforceImport/workforceImportSlice';
import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {WorkforceImportUpload} from './steps/Upload';
import {WorkforceImportReview} from './steps/Review';
import {DragAndDropMatching} from './steps/Validate';

export function ImportWorkflow() {
  const importState = useAppSelector(selectWorkforceImport);
  const dispatch = useAppDispatch();

  function resetState() {
    dispatch(reset());
  }

  return (
    <Box sx={{
      display: 'flex', flexGrow: 1,
      justifyContent: 'center', flexDirection: 'column'
    }}>
      {/*{importState.errors.length > 0 ? <ApiErrorAlert possibleErrors={importState.errors}/> : <></>}*/}

      <Accordion expanded={importState.currentStep === 'upload'}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="upload-panel-content" id="upload-panel-header">
          <Typography sx={{width: '33%', flexShrink: 0}}>Upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <WorkforceImportUpload/>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={importState.currentStep === 'validate'}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}
          aria-controls="upload-validate-content" id="upload-validate-header">
          <Typography sx={{width: '33%', flexShrink: 0}}>Validate</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DragAndDropMatching/>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={importState.currentStep === 'review'}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}
          aria-controls="upload-validate-content" id="upload-validate-header">
          <Typography sx={{width: '33%', flexShrink: 0}}>Import & Review</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <WorkforceImportReview/>
        </AccordionDetails>
      </Accordion>

      <Box pt={2}>
        <Button onClick={resetState} variant={'contained'} color={'warning'}>Reset</Button>
      </Box>
    </Box>
  );
}