import {GridRowSelectionModel} from '@mui/x-data-grid/models/gridRowSelectionModel';
import {
  Employee,
  EmployeeConnection,
  useDeleteEmployeeMutation,
  useGetEmployeesWithDetailsLazyQuery,
  useUpdateEmployeeMutation
} from '../../../generated/graphql';
import {useAppDispatch} from '../../../hooks/app';
import {FullFeaturedCrudGrid} from '../FullFeaturedCrudGrid';
import {GridColDef, GridRowId, GridRowModel, GridToolbarContainer} from '@mui/x-data-grid';
import {addMessage} from '../../../features/snackbar/snackbarSlice';
import {v4 as uuidv4} from 'uuid';
import {ApolloError, LazyQueryHookExecOptions, QueryResult} from '@apollo/client';
import {Button, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {useEffect, useState} from 'react';
import NewEmployeeModal from './NewEmployeeModal';
import ApiErrorAlert from '../../../components/common/ApiErrorAlert';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import EditEmployeeModal from './EditEmployeeModal';
import EditEmployeeAccountModal from './EditEmployeeAccountModal';

const sharedOptions: Partial<GridColDef> = {
  flex: 1,
};

const employeeColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    editable: true,
    ...sharedOptions,
  },
  {
    field: 'externalId',
    headerName: 'External Id',
    // minWidth: 300,
    editable: true,
    ...sharedOptions,
  },
  {
    field: 'email',
    headerName: 'Email',
    // minWidth: 300,
    editable: true,
    ...sharedOptions,
  },
  {
    field: 'businessUnitId',
    valueGetter: (params) => {
      return (params.row.businessUnit as { name: string }).name;
    },
    headerName: 'Business Unit',
    // minWidth: 200,
    editable: false,
    ...sharedOptions,
  },
  {
    field: 'divisionId',
    valueGetter: (params) => {
      return (params.row.division as { name: string }).name;
    },
    headerName: 'Division',
    // minWidth: 200,
    editable: false,
    ...sharedOptions,
  },
  {
    field: 'locationId',
    valueGetter: (params) => {
      return (params.row.location as { name: string }).name;
    },
    headerName: 'Location',
    // minWidth: 200,
    editable: false,
    ...sharedOptions,
  },
  {
    field: 'managerId',
    valueGetter: (params) => {
      if (!params.row.manager) {
        return '';
      }
      return (params.row.manager as Employee).email;
    },
    headerName: 'Manager',
    // minWidth: 200,
    editable: false,
    ...sharedOptions,
  },
  {
    field: 'title',
    headerName: 'Title',
    // minWidth: 200,
    editable: true,
    ...sharedOptions,
  },
  {
    field: 'role',
    headerName: 'Role',
    // minWidth: 200,
    editable: true,
    ...sharedOptions,
  },

  {
    field: 'deskPhone',
    headerName: 'Desk Phone',
    // minWidth: 150,
    editable: true,
    ...sharedOptions,
  },
  {
    field: 'mobilePhone',
    headerName: 'Mobile Phone',
    // minWidth: 150,
    editable: true,
    ...sharedOptions,
  },

  // {
  //   field: 'createdAt',
  //   headerName: 'Created At',
  //   editable: false,
  //   minWidth: 200,
  //   type: 'dateTime',
  //   valueGetter: (params) => {
  //     return new Date(params.row.created as string);
  //   }
  // },
  // {
  //   field: 'updatedAt',
  //   headerName: 'Updated At',
  //   editable: false,
  //   minWidth: 200,
  //   type: 'dateTime',
  //   valueGetter: (params) => {
  //     return new Date(params.row.updated as string);
  //   }
  // }
];

interface EmployeeTableProps {
  handleRowSelection: (rowSelectionModel: GridRowSelectionModel, selected: Employee[]) => void,
}

export function fetchEmployees<T extends LazyQueryHookExecOptions, R extends QueryResult>(getEmployees: (options?: Partial<T>) => Promise<R>,
  pageSize: number, setValues: (value: (((prevState: (Employee[] | undefined)) => (Employee[] | undefined)) | Employee[] | undefined)) => void) {
  const queryResult = async (after: string | null): Promise<EmployeeConnection | undefined> => {
    try {
      //@ts-ignore
      const data = await getEmployees({
        variables: {
          first: pageSize,
          after: after,
        }
      });
      return data.data?.employees as EmployeeConnection;
    } catch (error) {
      // setError(error as ApolloError);
    }
  };

  const fetchData = async () => {
    const data = await queryResult(null);
    let nodes = data?.nodes as Employee[];
    let pageInfo = data?.pageInfo;

    while (pageInfo && pageInfo?.hasNextPage) {
      const newData = await queryResult(pageInfo.endCursor);

      if (newData) {
        nodes = nodes.concat(newData.nodes as Employee[]);
        pageInfo = newData.pageInfo;
      } else {
        pageInfo = undefined;
      }
    }
    setValues(nodes);
  };
  return fetchData;
}

export function EmployeeTable(props: EmployeeTableProps) {
  const dispatch = useAppDispatch();
  const [values, setValues] = useState<Employee[] | undefined>(undefined);
  const [pageSize, setPageSize] = useState(10);
  const [getEmployees, {loading, error}] = useGetEmployeesWithDetailsLazyQuery({
    fetchPolicy: 'network-only',
    // nextFetchPolicy: 'cache-first',
  });
  const [deleteEmployeeMutation] = useDeleteEmployeeMutation();
  const [updateEmployeeMutation] = useUpdateEmployeeMutation();
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const fetchData = fetchEmployees(getEmployees, pageSize, setValues);

  const selectedRows: Employee[] = values?.filter((x) => selected.includes(x.id)) || [];

  // Gather all data for the table
  useEffect(() => {
    fetchData().then();
  }, [pageSize]);

  // Handle the row selection
  useEffect(() => {
    props.handleRowSelection(selected, selectedRows);
  }, [selected]);

  if (error) {
    return <ApiErrorAlert title="Error loading employees" possibleErrors={[
      error.message,
    ]}/>;
  }

  if (values === undefined) {
    return <>Loading...</>;
  }

  const processRowUpdate = (newRow: GridRowModel): Promise<GridRowModel> => {
    // console.log('processRowUpdate', {newRow});
    return new Promise((resolve, reject) => {
      updateEmployeeMutation({
        variables: {
          id: newRow.id,
          input: {
            name: newRow.name as string,
            externalId: newRow.externalId as string,
            email: newRow.email as string,
            title: newRow.title as string,
            role: newRow.role as string,
            // businessUnitId: newRow.businessUnitId as string,
            // divisionId: newRow.divisionId as string,
            // locationId: newRow.locationId as string,
            // managerId: newRow.managerId as string,
            deskPhone: newRow.deskPhone as string,
            mobilePhone: newRow.mobilePhone as string,
          }
        }
      })
        .then((value) => {
          dispatch(addMessage({
            id: uuidv4(),
            message: 'Employee updated',
            severity: 'success',
            autoHideDuration: 3000,
          }));

          fetchData()
            .then()
            .finally(() => {
              resolve(value.data?.updateEmployee as Employee);
            });

        })
        .catch((error: ApolloError) => {
          if (error.message.includes('violates unique constraint')) {
            dispatch(addMessage({
              id: uuidv4(),
              message: 'Employee name and external id must be unique',
              severity: 'error',
              autoHideDuration: 3000,
            }));
            return;
          }

          if (error.message.includes('no changes')) {
            dispatch(addMessage({
              id: uuidv4(),
              message: 'No changes',
              severity: 'warning',
              autoHideDuration: 3000,
            }));
            return;
          }

          console.error(error);
          dispatch(addMessage({
            id: uuidv4(),
            message: 'Error updating employee',
            severity: 'error',
            autoHideDuration: 3000,
          }));
          reject(error);
        });
    });
  };

  const handleDeleteClick = (id: GridRowId) => {
    console.log({action: 'delete', id});
    deleteEmployeeMutation({
      variables: {id: id}
    })
      .then(() => {
        dispatch(addMessage({
          id: uuidv4(),
          message: 'Employee deleted',
          severity: 'success',
          autoHideDuration: 3000,
        }));
      })
      .catch((error: ApolloError) => {
        if (error.message.includes('violates foreign key constraint')) {
          dispatch(addMessage({
            id: uuidv4(),
            message: 'Cannot delete employee with divisions',
            severity: 'error',
            autoHideDuration: 3000,
          }));
          return;
        }

        console.error(error);
        dispatch(addMessage({
          id: uuidv4(),
          message: 'Error deleting employee',
          severity: 'error',
          autoHideDuration: 3000,
        }));
      })
      .finally(() => {
        fetchData().then();
      });
  };

  function EditToolbar() {
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
      setModalOpen(true);
    };
    const handleCloseModal = () => {
      fetchData().then();
      setModalOpen(false);
    };

    const [editModalOpen, setEditModalOpen] = useState(false);
    const handleEditOpenModal = () => {
      setEditModalOpen(true);
    };
    const handleEditCloseModal = () => {
      fetchData().then();
      setEditModalOpen(false);
    };


    const [accountModalOpen, setAccountModalOpen] = useState(false);
    const handleAccountOpenModal = () => {
      setAccountModalOpen(true);
    };
    const handleAccountCloseModal = () => {
      fetchData().then();
      setAccountModalOpen(false);
    };


    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon/>} onClick={() => {
          handleOpenModal();
        }}>
          Add record
        </Button>
        <NewEmployeeModal open={modalOpen} handleClose={handleCloseModal}/>

        <Button color={'primary'} startIcon={<RefreshIcon/>} onClick={() => {
          fetchData().then();
        }}>
          Refresh
        </Button>
        {
          selectedRows.length === 1 &&
          <EditEmployeeModal open={editModalOpen} employee={selectedRows[0]} handleClose={handleEditCloseModal}/>
        }
        <Button color={'primary'} startIcon={<EditIcon/>}
          disabled={selected.length !== 1}
          onClick={handleEditOpenModal}>
          Edit
        </Button>

        {
          selectedRows.length === 1 &&
          <EditEmployeeAccountModal open={accountModalOpen} employeeId={selectedRows[0].id}
            handleClose={handleAccountCloseModal}/>
        }
        <Button color={'primary'} startIcon={<EditIcon/>}
          disabled={selected.length !== 1}
          onClick={handleAccountOpenModal}>
          Accounts
        </Button>
      </GridToolbarContainer>
    );
  }

  return (
    <FullFeaturedCrudGrid
      loading={loading}
      rows={values}
      columns={employeeColumns}
      handleRowSelection={rowSelectionModel => {
        setSelected(rowSelectionModel);
      }}
      processRowUpdate={processRowUpdate}
      handleDeleteClick={handleDeleteClick}
      slots={{
        toolbar: EditToolbar,
      }}
      onPaginationModelChange={(pagination) => {
        if (pagination.pageSize !== pageSize) {
          setPageSize(pagination.pageSize);
        }
      }}
      disableEdit
    />
  );
}

export function EmployeeList() {
  return (
    <>
      <Typography variant="h5" component="h2" gutterBottom>
        Employees
      </Typography>
      <EmployeeTable handleRowSelection={() => {
      }}/>
    </>
  );
}