import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import UserService from '../../services/user-service';
import {RootState} from '../../store';

export interface AuthState {
  email: null | string,
  userName: null | string,
  userId: null | string,
  isAuthenticated: boolean,
}

export const loginAsync = createAsyncThunk(
  'auth/login',
  async () => {
    if (!UserService.isLoggedIn()) {
      console.log('not logged in');
      const response = await UserService.doLogin({
        prompt: 'login'
      });
      console.log(response);
      return response;
    } else {
      return;
    }
  }
);

export const logoutAsync = createAsyncThunk(
  'auth/logout',
  async () => {
    await UserService.doLogout({
      redirectUri: window.location.origin + '/'
    });
  }
);

const initialState: AuthState = {
  email: null,
  userName: null,
  userId: null,
  isAuthenticated: false
};

function updateState(state: AuthState) {
  state.isAuthenticated = UserService.isLoggedIn();
  state.email = UserService.getEmail();
  state.userName = UserService.getUsername();
  state.userId = UserService.getUserId() || null;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => initialState,
    checkLogin: (state) => {
      updateState(state);
      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(loginAsync.pending, (state, action) => {
        console.log('pending', action);
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        updateState(state);
      })
      .addCase(loginAsync.rejected, (state, action) => {
        console.log('rejected', action);
      })
      // Logout
      .addCase(logoutAsync.pending, (state, action) => {
        console.log('pending', action);
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        console.log('fulfilled', action);
        return initialState;
      })
      .addCase(logoutAsync.rejected, (state, action) => {
        console.log('rejected', action);
      });
  },
});

export const {logout, checkLogin} = authSlice.actions;
export default authSlice.reducer;

export const selectAuth = (state: RootState) => state.auth;
export const isAuthenticated = (state: RootState) => state.auth.isAuthenticated;
