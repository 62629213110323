import React, {useState} from 'react';
import {Box, Button, Modal, Typography} from '@mui/material';
import {
  Employee,
  EmployeeAssociationInput,
  EmployeeCreateInput,
  EmployeeUpdateInput,
  useUpdateEmployeeAssociationMutation,
  useUpdateEmployeeMutation
} from '../../../generated/graphql';
import {EmployeeForm} from './EmployeeForm';
import {addMessage} from '../../../features/snackbar/snackbarSlice';
import {v4 as uuidv4} from 'uuid';
import {useAppDispatch} from '../../../hooks/app';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

interface EditEmployeeModalProps {
  open: boolean,
  employee: Employee,
  handleClose: () => void,
}

const EditEmployeeModal = ({open, employee, handleClose}: EditEmployeeModalProps) => {
  const dispatch = useAppDispatch();
  const [formValid, setFormValid] = useState<boolean>(false);
  const [employeeValues, setEmployeeValues] = useState<EmployeeCreateInput>(employee);
  const [updateEmployeeMutation] = useUpdateEmployeeMutation();
  const [updateEmployeeAssocMutation] = useUpdateEmployeeAssociationMutation();

  const updateEmployee = async (id: string, input: EmployeeUpdateInput, assocInput: EmployeeAssociationInput) => {
    // console.log({id, input, assocInput});

    // Only update the employee association if there are changes. check if all keys are undefined
    // @ts-ignore
    if (!Object.keys(assocInput).every((k) => assocInput[k] === undefined)) {
      await updateEmployeeAssocMutation({
        variables: {
          id: id,
          input: assocInput,
        }
      });
    }

    // Only update the employee if there are changes. check if all keys are undefined
    // @ts-ignore
    if (!Object.keys(input).every((k) => input[k] === undefined)) {
      await updateEmployeeMutation({
        variables: {
          id: id,
          input: input,
        }
      });
    }
  };

  const handleSubmit = () => {
    // Handle the submission logic here
    // For example: send this data to an API or update the state in a parent component

    // Get the updated employee values from the form
    const changedValues: EmployeeUpdateInput = {
      name: employee.name != employeeValues.name ? employeeValues.name : undefined,
      email: employee.email != employeeValues.email ? employeeValues.email : undefined,
      externalId: employee.externalId != employeeValues.externalId ? employeeValues.externalId : undefined,
      title: employee.title != employeeValues.title && employeeValues.title != '' ? employeeValues.title : undefined,
      role: employee.role != employeeValues.role && employeeValues.role != '' ? employeeValues.role : undefined,
      deskPhone: employee.deskPhone != employeeValues.deskPhone && employeeValues.deskPhone != '' ? employeeValues.deskPhone : undefined,
      mobilePhone: employee.mobilePhone != employeeValues.mobilePhone && employeeValues.mobilePhone != '' ? employeeValues.mobilePhone : undefined,
    };

    const changedEmployeeAssoc: EmployeeAssociationInput = {
      businessUnitId: employee.businessUnitId != employeeValues.businessUnitId ? employeeValues.businessUnitId : undefined,
      divisionId: employee.divisionId != employeeValues.divisionId ? employeeValues.divisionId : undefined,
      locationId: employee.locationId != employeeValues.locationId ? employeeValues.locationId : undefined,
      managerId: employee.managerId != employeeValues.managerId ? employeeValues.managerId : undefined,
    };

    updateEmployee(employee.id, changedValues, changedEmployeeAssoc)
      .then(() => {
        dispatch(addMessage({
          id: uuidv4(),
          message: 'Employee updated successfully',
          severity: 'success',
          autoHideDuration: 3000,
        }));
      })
      .catch((error) => {
        dispatch(addMessage({
          id: uuidv4(),
          message: error.message,
          severity: 'error',
          autoHideDuration: 3000,
        }));
      })
      .finally(() => {
        handleClose();
      });
  };

  return (
    <Modal open={open} onClose={handleClose}
      aria-labelledby="create-new-employee"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} component={'form'}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Edit Employee
        </Typography>
        <EmployeeForm employee={employee} onUpdate={(e, valid) => {
          // console.log({e, valid});
          setEmployeeValues(e);
          setFormValid(valid);
        }}/>
        <Button variant="contained" onClick={handleSubmit} disabled={!formValid}>
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default EditEmployeeModal;
