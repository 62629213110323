import {Box} from '@mui/material';
import {ImportWorkflow} from './ImportWorkflow';

export function WorkforceImport() {
  return (
    <>
      <Box margin={2} flexGrow={1}>
        <ImportWorkflow/>
      </Box>
    </>
  );
}