import {HttpTest} from '../../../../generated/graphql';
import {GridColDef} from '@mui/x-data-grid';
import {ArrayCrudView} from './ArrayCrudView';

interface HttpTestsProps {
  value: Array<HttpTest>;
  onUpdate: (value: (((prevState: Array<HttpTest>) => Array<HttpTest>) | Array<HttpTest>)) => void;
}

const sharedOptions: Partial<GridColDef> = {
  editable: true,
  type: 'number',
  headerAlign: 'center',
  align: 'center',
  flex: 1,
};

const columns: GridColDef[] = [
  {
    field: 'url',
    headerName: 'URL',
    editable: true,
    flex: 1,
    align: 'left',
  },
  {
    field: 'redirects',
    headerName: 'Redirects',
    ...sharedOptions,
  },
  {
    field: 'timeout',
    headerName: 'Timeout',
    ...sharedOptions,
  },
  {
    field: 'interval',
    headerName: 'Interval',
    ...sharedOptions,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    ...sharedOptions,
  }
];

export function HttpTestsView({value, onUpdate}: HttpTestsProps) {
  return (<ArrayCrudView columns={columns} rows={value} onUpdate={onUpdate}
    newValue={(): HttpTest => ({
      url: 'https://example.com',
      redirects: 5,
      timeout: 10,
      interval: 300,
      priority: 1000,
    })}
  />);
}

