/* eslint-disable @typescript-eslint/no-explicit-any */
import Keycloak from 'keycloak-js';
import {ApolloClient} from '@apollo/react-hooks';
import {createHttpLink, InMemoryCache} from '@apollo/react-hoc';
import {setContext} from '@apollo/client/link/context';
import UserService from './services/user-service';

const BASENAME = process.env.PUBLIC_URL || (window as any).PUBLIC_URL || 'http://localhost:3000';
const LOG_LEVEL = process.env.LOG_LEVEL || 'ERROR';
const COMPANY_ID = process.env.COMPANY_ID || (window as any).COMPANY_ID || '100';
const API_BASE_URL = process.env.API_BASE_URL || (window as any).API_BASE_URL || 'http://localhost:8080' || 'https://api.beta.c01.truiem.xyz';

function getAccessOverride() {
  return null;
}

export const config = {
  app: {
    basename: BASENAME,
    logLevel: LOG_LEVEL,
    companyId: COMPANY_ID,
    features: {
      experienceScoresSummary: true,
      experienceScoreUser: true,
      multipleResources: true,
    },
    accessOverride: getAccessOverride(),
  }
};

export const keycloakClient = new Keycloak({
  url: process.env.KC_URL || (window as any).KC_URL || 'https://auth.c01.truiem.xyz/auth',
  realm: process.env.KC_REALM || (window as any).KC_REALM || 'alpha',
  clientId: process.env.KC_CLIENT_ID || (window as any).KC_CLIENT_ID || 'frontend'
});

const authLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  const token = UserService.getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  };
});

export const graphqlClient = new ApolloClient({
  link: authLink.concat(createHttpLink({
    uri: `${API_BASE_URL}/graphql`,
    credentials: 'include',
    headers: {
      'Access-Control-Allow-Origin': BASENAME,
      'Access-Control-Allow-Credentials': 'true',
    },
  })),
  cache: new InMemoryCache({}),
});

export default config;
