import {FormControl, InputLabel, MenuItem, Select, SelectProps} from '@mui/material';
import {useEffect, useState} from 'react';

export interface ValidatedSelectProps extends Omit<SelectProps, 'variant' | 'value' | 'onChange'> {
  validate: (value: string) => boolean,
  values?: { id: string, name: string }[],
  variant?: 'standard' | 'filled' | 'outlined',
  onChange: (value: (string)) => void,
  emptyValue?: string,
  emptyLabel?: string,
  value: string,
}

export function ValidatedSelect({
  values, validate, variant, onChange, value,
  emptyValue = '', emptyLabel = 'None',
  ...props
}: ValidatedSelectProps) {
  const [valid, setValid] = useState<boolean>(validate(value));

  useEffect(() => {
    setValid(validate(value));
  }, [value]);

  return (<FormControl fullWidth>
    <InputLabel id={props.id + '-label'} required={props.required}
      disabled={props.disabled} error={!valid}>{props.label}</InputLabel>
    <Select value={value} variant={variant}
      error={!valid} labelId={props.id + '-label'}
      onChange={event => {
        const newValue = event.target.value as string;
        setValid(validate(newValue));
        onChange(newValue);
      }}
      {...props as Omit<SelectProps, 'variant' | 'value' | 'onChange' | 'error'>}
    >
      <MenuItem value={emptyValue}><em>{emptyLabel}</em></MenuItem>
      {values?.map((x) => (
        <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
      ))}
    </Select>
  </FormControl>
  );
}