import authSlice from './features/auth/authSlice';
import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import workforceImportSlice from './features/workforceImport/workforceImportSlice';
import snackbarSlice from './features/snackbar/snackbarSlice';

// Create the root reducer separately, so we can extract the RootState type
const rootReducer = combineReducers({
  auth: authSlice,
  workforceImport: workforceImportSlice,
  snackbar: snackbarSlice,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];

setupListeners(store.dispatch);
