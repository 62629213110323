import {Location, useGetLocationsLazyQuery} from '../../../generated/graphql';
import {useEffect, useState} from 'react';

import {ValidatedSelect, ValidatedSelectProps} from '../../../components/common/form/ValidatedSelect';
import {fetchLocations} from '../location/LocationTable';

type LocationDropdownProps = {
  variant?: 'standard' | 'filled' | 'outlined',
  setValue: (value: (string)) => void,
  validate: (value: string) => boolean,
  value: string,
}

export function LocationDropdown({
  variant = 'outlined',
  setValue, validate, value,
  ...props
}: LocationDropdownProps & Omit<ValidatedSelectProps, 'variant' | 'value' | 'onChange'>) {
  const [values, setValues] = useState<Location[] | undefined>(undefined);
  const [getLocations, {loading, error}] = useGetLocationsLazyQuery({
    // fetchPolicy: 'network-only',
    fetchPolicy: 'no-cache',
    // nextFetchPolicy: 'cache-first',
  });
  const fetchData = fetchLocations(getLocations, 50, setValues);

  useEffect(() => {
    fetchData().then();
  }, []);

  return <ValidatedSelect
    onChange={setValue}
    values={values}
    validate={validate}
    variant={variant}
    disabled={!!(error || loading || !values)}
    value={value}
    {...props} />;
}