import {Component, PropsWithChildren} from 'react';
import {Provider} from 'react-redux';
import {store} from '../store';
import {ThemeProvider} from '@mui/material';
import theme from '../theme';
import GlobalCssOverride from '../theme/GlobalCssOverride';
import {UserClaimsProvider} from '../context/UserClaimsContext';
import {I18nContextProvider} from '../hooks/useI18n';
import {ApolloProvider} from '@apollo/react-components';
import {graphqlClient} from '../config';

export class SiteWrapper extends Component<PropsWithChildren> {
  render() {
    return (
      <ApolloProvider client={graphqlClient}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <UserClaimsProvider>
              <GlobalCssOverride/>
              <I18nContextProvider>
                {this.props.children}
              </I18nContextProvider>
            </UserClaimsProvider>
          </ThemeProvider>
        </Provider>
      </ApolloProvider>
    );
  }
}