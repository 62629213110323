import React, {useEffect, useState} from 'react';
import {Box, Button, Modal, TextField, Typography} from '@mui/material';
import {useCreateDivisionMutation} from '../../../generated/graphql';
import {useAppDispatch} from '../../../hooks/app';
import {addMessage} from '../../../features/snackbar/snackbarSlice';
import {v4 as uuidv4} from 'uuid';
import {ValidateRequiredString, ValidTextField} from '../../../components/common/form/ValidTextField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

interface NewBusinessUnitModalProps {
  businessUnitId: string,
  open: boolean,
  handleClose: () => void,
}

const NewDivisionModal = ({businessUnitId, open, handleClose}: NewBusinessUnitModalProps) => {
  const dispatch = useAppDispatch();
  const [formValid, setFormValid] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [externalId, setExternalId] = useState('');
  const [createDivisionMutation] = useCreateDivisionMutation();

  const clearForm = () => {
    setName('');
    setExternalId('');
  };

  const handleSubmit = () => {
    // Handle the submission logic here
    // For example: send this data to an API or update the state in a parent component
    console.log({name, externalId});
    createDivisionMutation({
      variables: {
        input: {
          businessUnitId,
          name,
          externalId: (externalId === '' ? undefined : externalId),
        }
      },
    })
      .then(() => {
        dispatch(addMessage({
          id: uuidv4(),
          message: 'Division created successfully',
          severity: 'success',
          autoHideDuration: 3000,
        }));
        handleClose(); // Close the modal after submission
      })
      .catch((error) => {
        dispatch(addMessage({
          id: uuidv4(),
          message: error.message,
          severity: 'error',
          autoHideDuration: 3000,
        }));
      });
  };

  useEffect(() => {
    setFormValid(ValidateRequiredString(name));
  }, [
    name, externalId,
  ]);

  return (
    <Modal open={open} onClose={() => {
      clearForm();
      handleClose();
    }}
    aria-labelledby="create-new-business-unit"
    aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create New Business Unit
        </Typography>
        <ValidTextField required fullWidth
          label="Name" value={name} setValue={setName}
          validate={ValidateRequiredString}
        />
        <TextField
          label="External ID"
          variant="outlined"
          fullWidth
          value={externalId}
          onChange={(e) => setExternalId(e.target.value)}
        />
        <Button variant="contained" onClick={handleSubmit} disabled={!formValid}>
          Create
        </Button>
      </Box>
    </Modal>
  );
};

export default NewDivisionModal;
