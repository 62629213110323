import {useEffect, useState} from 'react';
import {Division, useGetDivisionsLazyQuery} from '../../../generated/graphql';
import {fetchDivisions} from '../business-unit/DivTable';
import {ValidatedSelect, ValidatedSelectProps} from '../../../components/common/form/ValidatedSelect';

type DivisionDropdownProps = {
  businessUnitId: string,
  variant?: 'standard' | 'filled' | 'outlined',
  setValue: (value: (string)) => void,
  validate: (value: string) => boolean,
  value: string,
}

export function DivisionDropdown({
  businessUnitId,
  variant = 'outlined',
  setValue, validate, value,
  ...props
}: DivisionDropdownProps & Omit<ValidatedSelectProps, 'variant' | 'value' | 'onChange'>) {
  const [values, setValues] = useState<Division[] | undefined>(undefined);
  const [getDivisions, {loading, error}] = useGetDivisionsLazyQuery({
    // fetchPolicy: 'network-only',
    fetchPolicy: 'no-cache',
    // nextFetchPolicy: 'cache-first',
    variables: {
      condition: {
        businessUnitId: businessUnitId
      }
    }
  });

  const fetchData =  fetchDivisions(businessUnitId, getDivisions, 50, setValues);

  useEffect(() => {
    if (businessUnitId === props.emptyValue ) {
      setValue(props.emptyValue);
      setValues(undefined);
    } else if (businessUnitId === '') {
      setValues(undefined);
    } else {
      fetchData().then();
    }
  }, [businessUnitId]);

  return <ValidatedSelect
    onChange={setValue}
    values={values}
    validate={validate}
    variant={variant}
    disabled={businessUnitId === '' || !values || loading || error !== undefined}
    value={value}
    {...props} />;
}

