import Keycloak from 'keycloak-js';
import {config, keycloakClient} from '../config';
import {store} from '../store';
import {checkLogin} from '../features/auth/authSlice';

let _kcInitialized = false;

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void) => {

  keycloakClient.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
    // flow: 'hybrid',
  })
    .then((authenticated) => {
      if (!authenticated) {
        console.log('user is not authenticated..!');
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);

  _kcInitialized = true;

  keycloakClient.onTokenExpired = () => {
    refreshToken();
  };
};

function refreshToken() {
  updateToken((refreshed) => {
    if (refreshed) {
      console.debug('Token refreshed');
    }
    return refreshed;
  })
    .finally(() => {
      store.dispatch(checkLogin());
    });
}

const doLogin = keycloakClient.login;

const doLogout = keycloakClient.logout;

const getToken = () => keycloakClient.token;

const isLoggedIn = () => !!keycloakClient.token;

const updateToken = (successCallback: ((value: boolean) => boolean | PromiseLike<boolean>) | null | undefined) =>
  keycloakClient.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const useKeycloak = (): { keycloak: Keycloak, initialized: boolean } => ({
  keycloak: keycloakClient,
  initialized: _kcInitialized,
});

const getUsername = () => keycloakClient.tokenParsed?.preferred_username;
const getEmail = () => keycloakClient.idTokenParsed?.email;
const getUserId = () => keycloakClient.idTokenParsed?.sub;
const getUserInfo = () => keycloakClient.userInfo;

const hasRole = (roles: string[]) => roles.some((role) => {
  if (config.app.accessOverride) {
    return config.app.accessOverride == role;
  }

  return keycloakClient.hasRealmRole(role) || keycloakClient.hasResourceRole(role);
});

export const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getEmail,
  getUserId,
  getUserInfo,
  useKeycloak,
};

export default UserService;
