import GlobalStyles from '@mui/material/GlobalStyles';

const GlobalCssOverride = () => {
  return (
    <GlobalStyles
      styles={{
        a: {color: 'inherit', textDecoration: 'none', '&:hover': {color: 'inherit'}},
        body: {
          fontFamily: ['Montserrat', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif'].join(',')
        }
      }}
    />
  );
};

export default GlobalCssOverride;
