import React, {PropsWithChildren, useReducer} from 'react';
import EN from '../locales/en/translation.json';

import {getSupportedBrowserLocales, Locale} from '../locales/localeDetection';

const en = 'en';

interface TranslationFile {
  [key: string]: string;
}

interface AvailableTranslations {
  [key: string]: TranslationFile;
}

interface I18nTranslationInfo {
  langCode: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  translate: (key: string, args?: any) => string;
}

// This is adapted from https://medium.com/@anilakarsu/internationalization-with-react-hooks-af37bed9f195 as a placeholder
// for a translation library.
const translations: AvailableTranslations = {en: EN};

const defaultLocale = {code: 'en-US', language: en};
const locales: Locale[] = getSupportedBrowserLocales();
const locale = locales.length > 0 ? locales[0] : defaultLocale;

export const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
export const dateFormatter = new Intl.DateTimeFormat(locale.code, {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
});

export const getDateFormatter = (timezone: string) => {
  return new Intl.DateTimeFormat(locale.code, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: timezone
  });
};

export const getTimeFormatter = (timezone: string) => {
  return new Intl.DateTimeFormat(locale.code, {
    timeStyle: 'short',
    hourCycle: 'h23',
    timeZone: timezone
  });
};

/*
  Translates the strings based on the provided language file. The optional arguments allow for template 
  based replacement. If provided the properties of the object must match the placeholders in the translation file. 
  Example: 
  translation file: Hello ${entity}! The weather is ${weather}
  would be called with: 
  getTranslate('hello_world_message', {entity: 'world', weather: 'Hot'})
  
  If the key is not found, or an error occurs during interpolation, the original key is returned. 
*/
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const getTranslate = (langCode: string) => (key: string, args?: any) => {
  const translationCode = langCode as keyof typeof translations;
  try {
    return interpolateTemplate(translations[translationCode][key], args) || interpolateTemplate(key);
  } catch (error) {
    return key;
  }
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function interpolateTemplate(template: string, args?: any): string {
  if (!args) {
    return template;
  }
  try {
    return Object.entries(args).reduce((result, [arg, val]) => result.replace(`$\{${arg}}`, `${val}`), template);
  } catch (e) {
    return template;
  }
}

const initialState = {
  langCode: locale.language,
  translate: getTranslate(locale.language)
};

export const I18nContext = React.createContext<I18nTranslationInfo>(initialState);

export const I18nContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const reducer = () => {
    return {...initialState};
  };
  const [state] = useReducer(reducer, initialState);

  return <I18nContext.Provider value={{...state}}>{children}</I18nContext.Provider>;
};

export const useI18nContext = (): I18nTranslationInfo => React.useContext(I18nContext);
