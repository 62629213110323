import {useState} from 'react';
import {TextField} from '@mui/material';
import {TextFieldProps} from '@mui/material/TextField/TextField';

interface ValidTextFieldProps {
  value: string,
  setValue: (value: string) => void,
  validate: (value: string) => boolean,
  variant?: 'standard' | 'filled' | 'outlined',
}

export function ValidTextField({
  value,
  setValue,
  validate,
  variant,
  ...props
}: ValidTextFieldProps & Omit<TextFieldProps, 'variant' | 'value'>) {
  const [valid, setValid] = useState<boolean>(validate(value));

  return (
    <TextField
      value={value}
      variant={variant || 'outlined'}
      onChange={(e) => {
        setValue(e.target.value);
        setValid(validate(e.target.value));
      }}
      error={!valid}
      {...props as TextFieldProps}
    />
  );
}

export function ValidateRequiredString(value: string): boolean {
  return value.length > 0;
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function ValidateRequiredEmail(value: string): boolean {
  return ValidateEmail(value, false);
}

export function ValidateEmail(value: string, optional: boolean = true): boolean {
  if (value.length === 0) {
    return optional;
  }

  return emailRegex.test(value);
}

const phoneRegex = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export function ValidatePhoneRequiredNumber(value: string): boolean {
  return ValidatePhoneNumber(value, false);
}

export function ValidatePhoneNumber(value: string, optional: boolean = true): boolean {
  if (value.length === 0) {
    return optional;
  }

  return phoneRegex.test(value);
}

const proxyUriRegex = /^(?:(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(?:https?:\/\/)?(?![\d.]+:)\w+(?:\.\w+)*:\d+\S+|\w+:\w+@(?![\d.]+:)\w+(?:\.\w+)*:\d+)$/;

export function ValidateProxyUri(value: string): boolean {
  return proxyUriRegex.test(value);
}
