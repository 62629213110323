import {closeMessage, selectSnackbar, SnackbarMessage} from './snackbarSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/app';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import {forwardRef} from 'react';
import MuiAlert, {AlertProps} from '@mui/material/Alert';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function GlobalSnackbar() {
  const snack = useAppSelector(selectSnackbar);
  const dispatch = useAppDispatch();

  const handleClose = (id: string, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeMessage(id));
  };
  const getSnack = (message: SnackbarMessage) => {
    const onClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      handleClose(message.id, reason);
    };

    return (
      <Snackbar open={true} key={message.id} autoHideDuration={message.autoHideDuration} onClose={onClose}>
        <Alert severity={message.severity} onClose={onClose}>
          {message.message}
        </Alert>
      </Snackbar>
    );
  };

  // Should only show 1 message at a time via MUI standards: https://m2.material.io/components/snackbars#behavior
  return (
    <Stack spacing={2} sx={{width: '100%'}}>
      {snack.messages.length > 0 && getSnack(snack.messages[0])}
    </Stack>
  );
}