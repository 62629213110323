import {useUserClaims} from '../context/UserClaimsContext';
import Grid from '@mui/material/Unstable_Grid2';
import {ResourceRole} from '../resources/ResourceRole';

export function UserInfo() {
  const {authUserClaims} = useUserClaims();
  const claims = authUserClaims.userClaims || {};
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{pt: 1}}>
      <h1>Welcome, {claims.email}!</h1>
      {claims.userInfo && <pre>{JSON.stringify(claims.userInfo, null, 2)}</pre>}
      {authUserClaims.accessLevel == ResourceRole.USER && <h2>You are an User!</h2>}
      {authUserClaims.accessLevel == ResourceRole.SUPERVISOR && <h2>You are a Supervisor!</h2>}
      {authUserClaims.accessLevel == ResourceRole.CLIENT_ADMIN && <h2>You are a Client Admin!</h2>}
      {authUserClaims.accessLevel == ResourceRole.ADMIN && <h2>You are an Admin!</h2>}
      <pre>{JSON.stringify(claims.token, null, 2)}</pre>
    </Grid>
  );
}