import {
  BusinessUnit,
  Division,
  DivisionConnection, GetDivisionsQuery, GetDivisionsQueryVariables,
  useDeleteDivisionMutation,
  useGetDivisionsLazyQuery,
  useUpdateDivisionMutation
} from '../../../generated/graphql';
import {useAppDispatch} from '../../../hooks/app';
import ApiErrorAlert from '../../../components/common/ApiErrorAlert';
import {Box, Button, Typography} from '@mui/material';
import {FullFeaturedCrudGrid} from '../FullFeaturedCrudGrid';
import {GridColDef, GridRowId, GridRowModel, GridToolbarContainer} from '@mui/x-data-grid';
import {addMessage} from '../../../features/snackbar/snackbarSlice';
import {v4 as uuidv4} from 'uuid';
import {ApolloError, LazyQueryHookExecOptions, QueryResult} from '@apollo/client';
import {useEffect, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import NewDivisionModal from './NewDivisionModal';

const divisionColumns: GridColDef[] = [
  {
    field: 'businessUnit.name',
    headerName: 'Business Unit',
    minWidth: 200,
    editable: false,
    valueGetter: (params) => {
      return (params.row as Division).businessUnit?.name;
    }
  },
  {
    field: 'name',
    headerName: 'Division',
    minWidth: 200,
    editable: true,
    flex: 1,
  },
  {
    field: 'externalId',
    headerName: 'External Id',
    minWidth: 300,
    editable: true,
    flex: 1,
  },
];

interface DivisionListProps {
  businessUnit: BusinessUnit | null,
}

export function fetchDivisions(buId: string, getDivisions: (options?: Partial<LazyQueryHookExecOptions<GetDivisionsQuery, GetDivisionsQueryVariables>>) => Promise<QueryResult<GetDivisionsQuery, GetDivisionsQueryVariables>>,
  pageSize: number, setValues: (value: (((prevState: (Division[] | undefined)) => (Division[] | undefined)) | Division[] | undefined)) => void) {
  const queryResult = async (after: string | null): Promise<DivisionConnection | undefined> => {
    try {
      const data = await getDivisions({
        variables: {
          first: pageSize,
          after: after,
          condition: {
            businessUnitId: buId,
          }
        }
      });
      return data.data?.divisions as DivisionConnection;
    } catch (error) {
      // setError(error as ApolloError);
    }
  };

  const fetchData = async () => {
    const data = await queryResult(null);
    let nodes = data?.nodes as Division[];
    let pageInfo = data?.pageInfo;

    while (pageInfo && pageInfo?.hasNextPage) {
      const newData = await queryResult(pageInfo.endCursor);

      if (newData) {
        nodes = nodes.concat(newData.nodes as Division[]);
        pageInfo = newData.pageInfo;
      } else {
        pageInfo = undefined;
      }
    }
    setValues(nodes);
  };
  return fetchData;
}

export function DivTable(props: DivisionListProps) {
  const dispatch = useAppDispatch();
  const [values, setValues] = useState<Division[] | undefined>(undefined);
  const [pageSize, setPageSize] = useState(10);
  const [getDivisions, {loading, error}] = useGetDivisionsLazyQuery({
    fetchPolicy: 'network-only',
    // nextFetchPolicy: 'cache-first',
  });
  const [deleteDivisionMutation] = useDeleteDivisionMutation();
  const [updateDivisionMutation] = useUpdateDivisionMutation();
  const fetchData = fetchDivisions( props.businessUnit?.id, getDivisions, pageSize, setValues);

  // Gather all data for the table
  useEffect(() => {
    if (props.businessUnit?.id) {
      fetchData().then();
    } else {
      setValues([]);
    }
  }, [pageSize, props.businessUnit?.id]);

  if (error) {
    return <ApiErrorAlert title="Error loading business units" possibleErrors={[
      error.message,
    ]}/>;
  }

  if (!props.businessUnit) {
    return <Box sx={{width: '100%'}}>
      <Typography variant="body1" component="p" gutterBottom>
        Select a Business Unit to see Divisions
      </Typography>
    </Box>;
  }

  if (values === undefined) {
    return <>Loading...</>;
  }

  const processRowUpdate = (newRow: GridRowModel): Promise<GridRowModel> => {
    return new Promise((resolve, reject) => {
      updateDivisionMutation({
        variables: {
          id: newRow.id as string,
          input: {
            name: newRow.name as string,
            externalId: newRow.externalId as string,
          }
        }
      })
        .then((value) => {
          dispatch(addMessage({
            id: uuidv4(),
            message: 'Division updated',
            severity: 'success',
            autoHideDuration: 3000,
          }));
          resolve(value.data?.updateDivision as Division);
        })
        .catch((error: ApolloError) => {
          if (error.message.includes('violates unique constraint')) {
            dispatch(addMessage({
              id: uuidv4(),
              message: 'Division name and external id must be unique',
              severity: 'error',
              autoHideDuration: 3000,
            }));
            return;
          }

          if (error.message.includes('no changes')) {
            dispatch(addMessage({
              id: uuidv4(),
              message: 'No changes',
              severity: 'warning',
              autoHideDuration: 3000,
            }));
            return;
          }

          console.error(error);
          dispatch(addMessage({
            id: uuidv4(),
            message: 'Error updating division',
            severity: 'error',
            autoHideDuration: 3000,
          }));
          reject(error);
        })
        .finally(() => {
          fetchData().then();
        });
    });
  };

  const handleDeleteClick = (id: GridRowId) => {
    deleteDivisionMutation({
      variables: {id: id}
    })
      .then(() => {
        dispatch(addMessage({
          id: uuidv4(),
          message: 'Division deleted',
          severity: 'success',
          autoHideDuration: 3000,
        }));
      })
      .catch((error: ApolloError) => {
        if (error.message.includes('violates foreign key constraint')) {
          dispatch(addMessage({
            id: uuidv4(),
            message: 'Cannot delete division with Employees',
            severity: 'error',
            autoHideDuration: 3000,
          }));
          return;
        }

        console.error(error);
        dispatch(addMessage({
          id: uuidv4(),
          message: 'Error deleting division',
          severity: 'error',
          autoHideDuration: 3000,
        }));
      })
      .finally(() => {
        fetchData().then();
      });
  };

  function EditToolbar() {
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => {
      fetchData().then();
      setModalOpen(false);
    };

    if (!props.businessUnit) {
      return null;
    }

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon/>} onClick={handleOpenModal}>
          Add record
        </Button>
        <NewDivisionModal businessUnitId={props.businessUnit.id} open={modalOpen} handleClose={handleCloseModal}/>
        <Button color={'primary'} startIcon={<RefreshIcon/>} onClick={() => {
          fetchData().then();
        }}>
          Refresh
        </Button>
      </GridToolbarContainer>
    );
  }

  return (
    <FullFeaturedCrudGrid
      loading={loading}
      rows={values}
      columns={divisionColumns}
      processRowUpdate={processRowUpdate}
      handleDeleteClick={handleDeleteClick}
      slots={{
        toolbar: EditToolbar,
      }}
      onPaginationModelChange={(pagination) => {
        if (pagination.pageSize !== pageSize) {
          setPageSize(pagination.pageSize);
        }
      }}
    />
  );
}