import React from 'react';
import './App.css';
import {store} from './store';
import UserService from './services/user-service';
import {checkLogin} from './features/auth/authSlice';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {routes} from './Routes';

export class App extends React.Component {
  constructor(props: NonNullable<unknown> | Readonly<NonNullable<unknown>>) {
    super(props);
    if (UserService.isLoggedIn()) {
      store.dispatch(checkLogin());
    }
  }

  render() {
    const router = createBrowserRouter(routes);
    return (
      <RouterProvider router={router}/>
    );
  }
}

export default App;
