import {ColumnData, ColumnMap, CreateData} from '../../services/import-service';
import {
  BusinessUnitCreateInput,
  DivisionCreateInput,
  EmployeeCreateInput,
  LocationCreateInput,
  ScheduleCreateInput,
  ScheduleType
} from '../../generated/graphql';
import {WorkforceImportState} from './workforceImportSlice';

import {stringToRFC3339Nano} from '../../utils/parseDate';

export function parseWorkforceImport(importState: WorkforceImportState) {
  const parseRowData: ColumnData[] = [];
  const sheets = Object.keys(importState.file!);
  const columnMap = importState.columnMap;

  sheets.forEach((sheetName) => {
    const columns = columnMap.filter((map) => map.sheet === sheetName);
    const data = importState.file![sheetName];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rowData = data.map((row: any) => {
      return parseRow(row, columns);
    });
    parseRowData.push(...rowData);
  });

  // console.log(parseRowData);
  const businessUnits: Map<string, BusinessUnitCreateInput> = new Map<string, BusinessUnitCreateInput>();
  const divisions: Map<string, DivisionCreateInput> = new Map<string, DivisionCreateInput>();
  const locations: Map<string, LocationCreateInput> = new Map<string, LocationCreateInput>();
  const employees: Map<string, EmployeeCreateInput> = new Map<string, EmployeeCreateInput>();

  parseRowData.forEach((row) => {
    parseBusinessUnits(row, businessUnits);
    parseDivisions(row, divisions);
    parseEmployees(row, employees);
    parseLocations(row, locations);
  });

  const data: CreateData = {
    businessUnits: Array.from(businessUnits.values()),
    divisions: Array.from(divisions.values()),
    locations: Array.from(locations.values()),
    employees: Array.from(employees.values()),
    schedules: [],
  };
  return {data, rows: parseRowData};
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseRow(row: any, columns: ColumnMap[]): ColumnData {
  const obj = {};
  columns.forEach((column) => {
    // @ts-ignore
    obj[column.type] = {};
  });

  columns.forEach((column) => {
    // @ts-ignore
    obj[column.type][column.instance] = row[column.column];
  });
  return obj as ColumnData;
}

function parseBusinessUnits(row: ColumnData, businessUnits: Map<string, BusinessUnitCreateInput>) {
  if (row.businessUnit && row.businessUnit.name) {
    if (businessUnits.has(row.businessUnit.name)) {
      const bu = businessUnits.get(row.businessUnit.name);
      if (bu && !bu.externalId && row.businessUnit.externalId) {
        bu.externalId = row.businessUnit.externalId;
      }
    } else {
      const bu: BusinessUnitCreateInput = {
        active: true,
        name: row.businessUnit.name,
        externalId: row.businessUnit.externalId,
      };
      businessUnits.set(row.businessUnit.name, bu);
    }
  }
}

function parseDivisions(row: ColumnData, divisions: Map<string, DivisionCreateInput>) {
  if (row.division && row.division.name && row.businessUnit.name) {
    const name = `${row.businessUnit.name} - ${row.division.name}`;
    if (divisions.has(name)) {
      const div = divisions.get(row.division.name);
      if (div && !div.externalId && row.division.externalId) {
        div.externalId = row.businessUnit.externalId;
      }
    } else {
      const div: DivisionCreateInput = {
        active: true,
        name: row.division.name,
        externalId: row.division.externalId,
        businessUnitId: row.businessUnit.name,
      };

      divisions.set(name, div);
    }
  }
}

function parseLocations(row: ColumnData, locations: Map<string, LocationCreateInput>) {
  if (row.location && !row.location.name && row.location.address) {
    row.location.name = row.location.address;
  }

  if (row.location && row.location.name) {
    if (locations.has(row.location.name)) {
      const loc = locations.get(row.location.name);
      if (loc) {
        if (!loc?.externalId && row.location.externalId) {
          loc.externalId = row.location.externalId;
        }

        if (!loc?.address && row.location.address) {
          loc.address = row.location.address;
        }

        if (!loc.lonLat && row.location.lat && row.location.lon) {
          loc.lonLat = {
            X: +row.location.lon,
            Y: +row.location.lat,
          };
        }

        if (!loc.lonLat && row.location.lonLat) {
          const parts = row.location.lonLat.split(',');
          if (parts.length === 2) {
            loc.lonLat = {
              X: +parts[0],
              Y: +parts[1],
            };
          }
        }
      }
    } else {
      const loc: LocationCreateInput = {
        active: true,
        name: row.location.name,
        externalId: row.location.externalId,
        address: row.location.address,
        lonLat: undefined,
        region: row.location.region
      };

      if (row.location.lat && row.location.lon) {
        loc.lonLat = {
          X: +(row.location.lon as string),
          Y: +(row.location.lat as string),
        };
      }

      if (row.location.lonLat) {
        const parts = row.location.lonLat.split(',');
        if (parts.length === 2) {
          loc.lonLat = {
            X: +parts[0],
            Y: +parts[1],
          };
        }
      }

      locations.set(row.location.name, loc);
    }
  }
}

function parseEmployees(row: ColumnData, employees: Map<string, EmployeeCreateInput>) {
  if (row.employee && row.employee.email) {
    let name = row.employee.firstName;

    if (name && row.employee.lastName) {
      name = `${row.employee.firstName} ${row.employee.lastName}`;
    }

    const email = row.employee.email;

    if (employees.has(email)) {
      const emp = employees.get(email);
      if (emp) {
        if (!emp?.externalId && row.employee.externalId) {
          emp.externalId = row.employee.externalId;
        }

        if (!emp?.email && row.employee.email) {
          emp.email = row.employee.email;
        }

        if (!emp?.deskPhone && row.employee.deskPhone) {
          emp.deskPhone = row.employee.deskPhone;
        }

        if (!emp?.mobilePhone && row.employee.mobilePhone) {
          emp.mobilePhone = row.employee.mobilePhone;
        }

        if (!emp?.title && row.employee.title) {
          emp.title = row.employee.title;
        }

        if (!emp?.role && row.employee.role) {
          emp.role = row.employee.role;
        }

        if (!emp?.hireDate && row.employee.hireDate) {
          emp.hireDate = stringToRFC3339Nano(row.employee.hireDate);
        }

        if (!emp?.terminationDate && row.employee.terminationDate) {
          emp.terminationDate = stringToRFC3339Nano(row.employee.terminationDate);
        }

        if (!emp?.hourlyWage && row.employee.hourlyWage) {
          // FIXME: hourly wage needs to be an integer
          // emp.hourlyWage = (!isNaN(Number(row.employee.hourlyWage)) ? Number(row.employee.hourlyWage) : undefined);
        }
      }
      //
    } else if (name) {
      const newEmp: EmployeeCreateInput = {
        businessUnitId: undefined,
        divisionId: undefined,
        locationId: undefined,
        active: true,
        name,
        externalId: row.employee.externalId,
        email: email,
        deskPhone: row.employee.deskPhone,
        mobilePhone: row.employee.mobilePhone,
        title: row.employee.title,
        role: row.employee.role,
        hireDate: stringToRFC3339Nano(row.employee.hireDate),
        terminationDate: stringToRFC3339Nano(row.employee.terminationDate),
        // FIXME: hourly wage needs to be an integer
        // hourlyWage: (!isNaN(Number(row.employee.hourlyWage)) ? Number(row.employee.hourlyWage) : undefined),
      };

      employees.set(email, newEmp);
    }
  }
}

export function parseSchedule(employeeId: string, row: ColumnData) {
  if (row.schedule && row.employee.email && row.schedule.start && row.schedule.end) {
    const type = row.schedule.type || ScheduleType.Shift;
    const newSched: ScheduleCreateInput = {
      active: true,
      start: stringToRFC3339Nano(row.schedule.start?.replace(/:000$/, '')),
      end: stringToRFC3339Nano(row.schedule.end?.replace(/:000$/, '')),
      type: type.toLowerCase() as ScheduleType,
      employeeId: employeeId,
    };
    return newSched;
  }
}