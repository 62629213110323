import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {ColumnData, ColumnMap, CreateData} from '../../services/import-service';

export interface StatusDetail {
  done: boolean;
  missing: [];
  complete: [];
  existing: [];
  failed: [];
  errors: string[];
}

export interface WorkforceImportState {
  currentStep: string,
  file: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  } | null,
  columnMap: ColumnMap[],
  rowData: ColumnData[],
  parsedData: CreateData,
  status: {
    [key: string]: StatusDetail
  }
}

function defaultStatusDetail(): StatusDetail {
  return {
    done: false,
    missing: [],
    complete: [],
    existing: [],
    failed: [],
    errors: [],
  };
}

const initialState: WorkforceImportState = {
  currentStep: 'upload',
  file: null,
  status: {
    location: defaultStatusDetail(),
    businessUnit: defaultStatusDetail(),
    division: defaultStatusDetail(),
    employee: defaultStatusDetail(),
    schedule: defaultStatusDetail(),
  },
  columnMap: [],
  rowData: [],
  parsedData: {
    locations: [],
    businessUnits: [],
    divisions: [],
    employees: [],
    schedules: [],
  }
};

const workforceImportSlice = createSlice({
  name: 'workforceImport',
  initialState,
  reducers: {
    reset: () => initialState,
    resetStatus: (state) => {
      state.parsedData = {
        locations: [],
        businessUnits: [],
        divisions: [],
        employees: [],
        schedules: [],
      };
      state.status = {
        location: defaultStatusDetail(),
        businessUnit: defaultStatusDetail(),
        division: defaultStatusDetail(),
        employee: defaultStatusDetail(),
        schedule: defaultStatusDetail(),
      };
    },

    setFile: (state, action) => {
      state.file = action.payload;
      return state;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setColumnMap: (state, action) => {
      state.columnMap = action.payload;
    },

    addColumnMap: (state, action) => {
      const newItem: ColumnMap = action.payload as ColumnMap;
      // Remove any double mapped columns
      const newMap: ColumnMap[] = [];
      state.columnMap.forEach((item) => {
        if (!(item.type === newItem.type && item.instance === newItem.instance && item.sheet === newItem.sheet)) {
          newMap.push(item);
        }
      });
      newMap.push(newItem);
      state.columnMap = newMap;
    },
    deleteColumnMap: (state, action) => {
      const newMap = state.columnMap.filter((item) => {
        return item.sheet != action.payload.sheet || item.column != action.payload.column;
      });
      state.columnMap = newMap;
    },

    setRowData: (state, action) => {
      state.rowData = action.payload;
    },
    setParsedData: (state, action) => {
      state.parsedData = action.payload;
    },

    setDone: (state, action) => {
      state.status[action.payload.type].done = action.payload.done;
    },

    addMissing: (state, action) => {
      // @ts-ignore
      state.status[action.payload.type].missing.push(action.payload.data);
      return state;
    },
    addComplete: (state, action) => {
      // @ts-ignore
      state.status[action.payload.type].complete.push(action.payload.data);
    },
    addExisting: (state, action) => {
      // @ts-ignore
      if (state.status[action.payload.type].existing.findIndex(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any) => {
          return action.payload.data.id === e.id;
        }) === -1) {
        // @ts-ignore
        state.status[action.payload.type].existing.push(action.payload.data);
      }
    },
    addFailed: (state, action) => {
      // @ts-ignore
      state.status[action.payload.type].failed.push(action.payload.data);

      if (action.payload.error) {
        if (state.status[action.payload.type].errors.indexOf(action.payload.error) === -1) {
          state.status[action.payload.type].errors.push(action.payload.error);
        }
      }
    },
    addError: (state, action) => {
      if (state.status[action.payload.type].errors.indexOf(action.payload.error) === -1) {
        state.status[action.payload.type].errors.push(action.payload.error);
      }
    }
  }
});

export const {
  reset, resetStatus, setFile, setDone,
  setCurrentStep, setColumnMap, setRowData, setParsedData,
  addMissing, addComplete, addExisting,
  addFailed, addError,
  addColumnMap, deleteColumnMap,
} = workforceImportSlice.actions;
export default workforceImportSlice.reducer;

export const selectWorkforceImport = (state: RootState) => state.workforceImport;
