import {useGetStatusQuery} from '../../generated/graphql';
import {useUserClaims} from '../../context/UserClaimsContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import {Typography} from '@mui/material';
import {CheckCircle, ErrorOutline} from '@mui/icons-material';
import {ResourceRole} from '../../resources/ResourceRole';
import {useEffect} from 'react';

export function StatusCard() {
  const getStatus = useGetStatusQuery();
  const {authUserClaims} = useUserClaims();

  function displayTime() {
    const now = new Date();
    const ele = document.getElementById('status-current-time');
    if (ele) {
      ele.innerText = now.toLocaleString();
    }
  }

  useEffect(() => {
    const claims = authUserClaims.userClaims;
    if (claims) {
      if (claims.token?.iat) {
        const issued = new Date(claims.token?.iat * 1000);
        const element = document.getElementById('status-issued-time');
        if (element) {
          element.innerText = issued.toLocaleString();
        }
      }

      if (claims.token?.exp) {
        const expire = new Date(claims.token?.exp * 1000);
        const element = document.getElementById('status-expire-time');
        if (element) {
          element.innerText = expire.toLocaleString();
        }
      }
    }
  }, [
    authUserClaims.isAuthenticated,
    authUserClaims.userClaims?.token?.iat,
    authUserClaims.userClaims?.token?.exp
  ]);

  displayTime();
  setInterval(displayTime, 1000); // Update every second

  return (
    <Card>
      <CardContent>
        <Grid container alignItems="center" justifyContent="center" minWidth={'33%'}>
          <Grid md={6} display={'flex'} alignItems="center" justifyContent="end" textAlign="center" mt={1}>
            <Typography mr={1}>Api Status:</Typography>
          </Grid>
          <Grid md={6} display={'flex'} alignItems="center" justifyContent="start" mt={1}>
            {
              getStatus.data?.status == 'OK' ?
                <CheckCircle color='success'></CheckCircle> :
                <ErrorOutline color='error'></ErrorOutline>
            }
          </Grid>

          <Grid md={6} display={'flex'} alignItems="center" justifyContent="end" textAlign="center" mt={1}>
            <Typography mr={1}>Logged in:</Typography>
          </Grid>
          <Grid md={6} display={'flex'} alignItems="center" justifyContent="start" mt={1}>
            {
              authUserClaims.isAuthenticated ?
                <CheckCircle color='success'></CheckCircle> :
                <ErrorOutline color='error'></ErrorOutline>
            }
          </Grid>

          <Grid md={6} display={'flex'} alignItems="center" justifyContent="end" textAlign="center" mt={1}>
            <Typography mr={1}>Email:</Typography>
          </Grid>
          <Grid md={6} display={'flex'} alignItems="center" justifyContent="start" mt={1}>
            <Typography>{authUserClaims.userClaims?.userInfo?.email}</Typography>
          </Grid>
          <Grid md={6} display={'flex'} alignItems="center" justifyContent="end" textAlign="center" mt={1}>
            <Typography mr={1}>Role:</Typography>
          </Grid>
          <Grid md={6} display={'flex'} alignItems="center" justifyContent="start" mt={1}>
            {authUserClaims.accessLevel == ResourceRole.USER && <Typography>User</Typography>}
            {authUserClaims.accessLevel == ResourceRole.SUPERVISOR && <Typography>Supervisor</Typography>}
            {authUserClaims.accessLevel == ResourceRole.CLIENT_ADMIN && <Typography>Client Admin</Typography>}
            {authUserClaims.accessLevel == ResourceRole.ADMIN && <Typography>Admin</Typography>}
            {authUserClaims.accessLevel == null && <Typography>None</Typography>}
          </Grid>

          <Grid md={6} display={'flex'} alignItems="center" justifyContent="end" textAlign="center" mt={1}>
            <Typography mr={1}>Current Time:</Typography>
          </Grid>
          <Grid md={6} display={'flex'} alignItems="center" justifyContent="start" mt={1}>
            <Typography id="status-current-time"></Typography>
          </Grid>

          <Grid md={6} display={'flex'} alignItems="center" justifyContent="end" textAlign="center" mt={1}>
            <Typography mr={1}>Token Issued:</Typography>
          </Grid>
          <Grid md={6} display={'flex'} alignItems="center" justifyContent="start" mt={1}>
            <Typography id="status-issued-time"></Typography>
          </Grid>

          <Grid md={6} display={'flex'} alignItems="center" justifyContent="end" textAlign="center" mt={1}>
            <Typography mr={1}>Token Expire:</Typography>
          </Grid>
          <Grid md={6} display={'flex'} alignItems="center" justifyContent="start" mt={1}>
            <Typography id="status-expire-time"></Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}