import {Box, Typography} from '@mui/material';
import {TestTargetsTable} from './TestTargetsTable';
import {useState} from 'react';
import {TestTarget} from '../../../generated/graphql';
import {TestTargetView} from './TestTargetView';

export function TestTargetsList() {
  const [selected, setSelected] = useState<TestTarget | undefined>(undefined);
  // FIXME: This is a hack to force the table to reload when a test target is updated
  const [refresh, setRefresh] = useState<string>('');

  return (
    <Box sx={{padding: 1}}>
      <Typography variant="h5" component="h2" gutterBottom>
        Test Targets
      </Typography>

      <Box sx={{marginBottom: 2}}>
        <TestTargetsTable reload={refresh}
          handleRowSelection={(rowSelectionModel, s) => {
            if (rowSelectionModel.length === 1) {
              setSelected(s[0]);
            } else {
              setSelected(undefined);
            }
          }}/>
      </Box>

      {selected && <TestTargetView target={selected} onUpdate={() => {
        setRefresh(new Date().getTime().toString());
      }}/>}
    </Box>
  );
}