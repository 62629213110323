import {GridRowSelectionModel} from '@mui/x-data-grid/models/gridRowSelectionModel';
import {
  GetLocationsQuery,
  GetLocationsQueryVariables,
  Location,
  LocationConnection,
  useDeleteLocationMutation,
  useGetLocationsLazyQuery,
  useUpdateLocationMutation
} from '../../../generated/graphql';
import {useAppDispatch} from '../../../hooks/app';
import ApiErrorAlert from '../../../components/common/ApiErrorAlert';
import {FullFeaturedCrudGrid} from '../FullFeaturedCrudGrid';
import {GridColDef, GridRowId, GridRowModel, GridToolbarContainer} from '@mui/x-data-grid';
import {addMessage} from '../../../features/snackbar/snackbarSlice';
import {v4 as uuidv4} from 'uuid';
import {ApolloError, LazyQueryHookExecOptions, QueryResult} from '@apollo/client';
import {Button, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import {useEffect, useState} from 'react';
import NewLocationModal from './NewLocationModal';

const sharedOptions = {
  flex: 1,
  editable: true,
};

const locationColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    // minWidth: 200,
    ...sharedOptions,
  },
  {
    field: 'externalId',
    headerName: 'External Id',
    // minWidth: 300,
    ...sharedOptions,
  },
  {
    field: 'address',
    headerName: 'Address',
    // minWidth: 500,
    ...sharedOptions,
  },
  {
    field: 'region',
    headerName: 'Region',
    // minWidth: 100,
    ...sharedOptions,
  },
  {
    field: 'lonLat',
    headerName: 'Lat/Long',
    // minWidth: 200,
    valueGetter: (params) => {
      if (!(params.row as Location).lonLat) {
        return '';
      }
      return `${((params.row as Location).lonLat?.X as number).toFixed(5)}, ${((params.row as Location).lonLat?.Y as number).toFixed(5)}`;
    },
    valueSetter: ({row, value}) => {
      // console.log({row, value});
      const [lat, long] = (value as string).split(',');
      if (isNaN(Number(lat)) || isNaN(Number(long))) {
        return row;
      }
      row.lonLat = {
        X: Number(lat),
        Y: Number(long),
      };
      return row;
    },
    ...sharedOptions,
  },
];

interface LocationTableProps {
  handleRowSelection: (rowSelectionModel: GridRowSelectionModel, selected: Location[]) => void,
}

export function fetchLocations(getLocations: (options?: Partial<LazyQueryHookExecOptions<GetLocationsQuery, GetLocationsQueryVariables>>) => Promise<QueryResult<GetLocationsQuery, GetLocationsQueryVariables>>, pageSize: number, setValues: (value: (((prevState: (Location[] | undefined)) => (Location[] | undefined)) | Location[] | undefined)) => void) {
  const queryResult = async (after: string | null): Promise<LocationConnection | undefined> => {
    try {
      const data = await getLocations({
        variables: {
          first: pageSize,
          after: after,
        }
      });
      return data.data?.locations as LocationConnection;
    } catch (error) {
      // console.log('error', error);
    }
  };

  const fetchData = async () => {
    const data = await queryResult(null);
    let nodes = data?.nodes as Location[];
    let pageInfo = data?.pageInfo;

    while (pageInfo && pageInfo?.hasNextPage) {
      const newData = await queryResult(pageInfo.endCursor);

      if (newData) {
        nodes = nodes.concat(newData.nodes as Location[]);
        pageInfo = newData.pageInfo;
      } else {
        pageInfo = undefined;
      }
    }
    setValues(nodes);
  };
  return fetchData;
}

export function LocationTable(props: LocationTableProps) {
  const dispatch = useAppDispatch();
  const [values, setValues] = useState<Location[] | undefined>(undefined);
  const [pageSize, setPageSize] = useState(10);
  const [getLocations, {loading, error}] = useGetLocationsLazyQuery({
    fetchPolicy: 'network-only',
    // nextFetchPolicy: 'cache-first',
  });
  const [deleteLocationMutation] = useDeleteLocationMutation();
  const [updateLocationMutation] = useUpdateLocationMutation();
  const fetchData = fetchLocations(getLocations, pageSize, setValues);

  // Gather all data for the table
  useEffect(() => {
    fetchData().then();
  }, [pageSize]);

  if (error) {
    return <ApiErrorAlert title="Error loading locations" possibleErrors={[
      error.message,
    ]}/>;
  }

  if (values === undefined) {
    return <>Loading...</>;
  }

  const processRowUpdate = (newRow: GridRowModel): Promise<GridRowModel> => {
    // console.log('processRowUpdate', {newRow});
    return new Promise((resolve, reject) => {
      updateLocationMutation({
        variables: {
          id: newRow.id,
          input: {
            name: newRow.name as string,
            externalId: newRow.externalId as string,
            address: newRow.address as string,
            region: newRow.region as string,
            lonLat: newRow.lonLat as { X: number, Y: number },
          }
        }
      })
        .then((value) => {
          dispatch(addMessage({
            id: uuidv4(),
            message: 'Location updated',
            severity: 'success',
            autoHideDuration: 3000,
          }));

          fetchData()
            .then()
            .finally(() => {
              resolve(value.data?.updateLocation as Location);
            });

        })
        .catch((error: ApolloError) => {
          if (error.message.includes('violates unique constraint')) {
            dispatch(addMessage({
              id: uuidv4(),
              message: 'Location name and external id must be unique',
              severity: 'error',
              autoHideDuration: 3000,
            }));
            return;
          }

          if (error.message.includes('no changes')) {
            dispatch(addMessage({
              id: uuidv4(),
              message: 'No changes',
              severity: 'warning',
              autoHideDuration: 3000,
            }));
            return;
          }

          console.error(error);
          dispatch(addMessage({
            id: uuidv4(),
            message: 'Error updating location',
            severity: 'error',
            autoHideDuration: 3000,
          }));
          reject(error);
        });
    });
  };

  const handleDeleteClick = (id: GridRowId) => {
    console.log({action: 'delete', id});
    deleteLocationMutation({
      variables: {id: id}
    })
      .then(() => {
        dispatch(addMessage({
          id: uuidv4(),
          message: 'Location deleted',
          severity: 'success',
          autoHideDuration: 3000,
        }));
      })
      .catch((error: ApolloError) => {
        if (error.message.includes('violates foreign key constraint')) {
          dispatch(addMessage({
            id: uuidv4(),
            message: 'Cannot delete location with divisions',
            severity: 'error',
            autoHideDuration: 3000,
          }));
          return;
        }

        console.error(error);
        dispatch(addMessage({
          id: uuidv4(),
          message: 'Error deleting location',
          severity: 'error',
          autoHideDuration: 3000,
        }));
      })
      .finally(() => {
        fetchData().then();
      });
  };

  function EditToolbar() {
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => {
      fetchData().then();
      setModalOpen(false);
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon/>} onClick={handleOpenModal}>
          Add record
        </Button>
        <NewLocationModal open={modalOpen} handleClose={handleCloseModal}/>
        <Button color={'primary'} startIcon={<RefreshIcon/>} onClick={() => {
          fetchData().then();
        }}>
          Refresh
        </Button>
      </GridToolbarContainer>
    );
  }

  return (
    <FullFeaturedCrudGrid
      loading={loading}
      rows={values}
      columns={locationColumns}
      handleRowSelection={props.handleRowSelection}
      processRowUpdate={processRowUpdate}
      handleDeleteClick={handleDeleteClick}
      slots={{
        toolbar: EditToolbar,
      }}
      onPaginationModelChange={(pagination) => {
        if (pagination.pageSize !== pageSize) {
          setPageSize(pagination.pageSize);
        }
      }}
    />
  );
}

export function LocationList() {
  return (
    <>
      <Typography variant="h5" component="h2" gutterBottom>
        Locations
      </Typography>
      <LocationTable handleRowSelection={() => {
      }}/>
    </>
  );
}