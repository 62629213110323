import {EmployeeCreateInput} from '../../../generated/graphql';
import React, {useEffect, useState} from 'react';
import {
  ValidatePhoneNumber,
  ValidateRequiredEmail,
  ValidateRequiredString,
  ValidTextField
} from '../../../components/common/form/ValidTextField';
import Grid2 from '@mui/material/Unstable_Grid2';
import {TextField} from '@mui/material';
import {BusinessUnitDropdown} from './BusinessUnitDropdown';
import {DivisionDropdown} from './DivisionDropdown';
import {LocationDropdown} from './LocationDropdown';
import {ManagerDropdown} from './ManagerDropdown';

interface EmployeeFormProps {
  employee: EmployeeCreateInput,
  onUpdate: (employee: EmployeeCreateInput, valid: boolean) => void,
}

export const EmployeeForm = ({employee, onUpdate}: EmployeeFormProps) => {
  const [name, setName] = useState(employee.name);
  const [email, setEmail] = useState(employee.email);
  const [externalId, setExternalId] = useState(employee.externalId || null);
  const [title, setTitle] = useState(employee.title || null);
  const [role, setRole] = useState(employee.role || null);
  const [deskPhone, setDeskPhone] = useState(employee.deskPhone || null);
  const [mobilePhone, setMobilePhone] = useState(employee.mobilePhone || null);
  const [businessUnitId, setBusinessUnitId] = useState(employee.businessUnitId);
  const [divisionId, setDivisionId] = useState<string>(employee.divisionId);
  const [locationId, setLocationId] = useState<string>(employee.locationId);
  const [managerId, setManagerId] = useState(employee.managerId || null);

  const validateForm = (): boolean => {
    return ValidateRequiredString(name) && ValidateRequiredEmail(email) &&
      ValidateRequiredString(businessUnitId) && ValidateRequiredString(divisionId) &&
      ValidateRequiredString(locationId);
  };
  const employeeValues = (): EmployeeCreateInput => ({
    name, email, externalId,
    title, role,
    deskPhone, mobilePhone,
    businessUnitId, divisionId,
    locationId,
    managerId: managerId === '' ? undefined : managerId,
  });

  useEffect(() => {
    onUpdate(employeeValues(), validateForm());
  }, [
    name, email,
    externalId, title, role,
    deskPhone, mobilePhone,
    businessUnitId, divisionId,
    locationId,
    managerId,
  ]);

  return (
    <Grid2 container spacing={2}>
      {/* Name */}
      <Grid2 xs={12} md={12}>
        <ValidTextField required fullWidth id={'employee-name'}
          label="Name" value={name} setValue={setName}
          validate={ValidateRequiredString}
        />
      </Grid2>
      {/* External ID */}
      <Grid2 xs={12} md={12}>
        <TextField fullWidth id={'employee-external-id'}
          label="External ID"
          value={externalId || ''}
          onChange={(e) => {
            const value = e.target.value;
            if (value === '') {
              setExternalId(null);
            } else {
              setExternalId(value);
            }
          }}
        />
      </Grid2>
      {/* Email */}
      <Grid2 xs={12} md={12}>
        <ValidTextField required fullWidth id={'employee-email'}
          label="Email" value={email} setValue={setEmail}
          validate={ValidateRequiredEmail}
        />
      </Grid2>
      {/* Title */}
      <Grid2 xs={6} md={6}>
        <TextField fullWidth id={'employee-title'}
          label="Title"
          value={title || ''}
          onChange={(e) => {
            const value = e.target.value;
            if (value === '') {
              setTitle(null);
            } else {
              setTitle(value);
            }
          }}
        />
      </Grid2>
      {/* Role */}
      <Grid2 xs={6} md={6}>
        <TextField fullWidth id={'employee-role'}
          label="Role"
          value={role || ''}
          onChange={(e) => {
            const value = e.target.value;
            if (value === '') {
              setRole(null);
            } else {
              setRole(value);
            }
          }}
        />
      </Grid2>

      {/* Desk Phone */}
      <Grid2 xs={6} md={6}>
        <ValidTextField fullWidth label="Desk Phone" id={'employee-desk-phone'}
          value={deskPhone || ''} setValue={(value) => {
            if (value === '') {
              setDeskPhone(null);
            } else {
              setDeskPhone(value);
            }
          }}
          validate={ValidatePhoneNumber}
        />
      </Grid2>
      {/* Mobile Phone */}
      <Grid2 xs={6} md={6}>
        <ValidTextField fullWidth label="Mobile Phone" id={'employee-mobile-phone'}
          value={mobilePhone || ''} setValue={(value) => {
            if (value === '') {
              setMobilePhone(null);
            } else {
              setMobilePhone(value);
            }
          }}
          validate={ValidatePhoneNumber}
        />
      </Grid2>

      {/* Business Unit */}
      <Grid2 xs={6} md={6}>
        <BusinessUnitDropdown required fullWidth label="Business Unit"
          id={'employee-business-unit-dropdown'}
          validate={ValidateRequiredString} value={businessUnitId}
          setValue={(v: string) => {
            if (v !== businessUnitId) {
              setBusinessUnitId(v);
              setDivisionId('');
            }
          }}/>
      </Grid2>
      {/* Division */}
      <Grid2 xs={6} md={6}>
        <DivisionDropdown required fullWidth label="Division"
          id={'employee-division-dropdown'}
          validate={ValidateRequiredString} value={divisionId}
          businessUnitId={businessUnitId} setValue={setDivisionId}/>
      </Grid2>

      <Grid2 xs={12} md={12}>
        <LocationDropdown required fullWidth label="Location"
          id={'employee-location'}
          validate={ValidateRequiredString} value={locationId}
          setValue={setLocationId}/>
      </Grid2>

      <Grid2 xs={12} md={12}>
        <ManagerDropdown fullWidth label="Manager"
          id={'employee-manager'}
          validate={() => {
            return true;
          }} value={managerId}
          setValue={value => {
            if (value !== '') {
              setManagerId(value);
            } else {
              setManagerId(null);
            }
          }}/>
      </Grid2>
    </Grid2>
  );
};