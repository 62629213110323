import {useAppSelector} from '../../../../hooks/app';
import {selectWorkforceImport} from '../../../../features/workforceImport/workforceImportSlice';
import {Box, Chip, Paper, Typography} from '@mui/material';
import React from 'react';
import {styled} from '@mui/material/styles';
import {useDrag} from 'react-dnd';
import {ColumnMap, RequiredColumns} from '../../../../services/import-service';

const SuccessPaper = styled(Paper)(({theme}) => ({
  padding: theme.spacing(2),
  ...theme.typography.body2,
  backgroundColor: theme.palette.success.main,
}));

export const WarningPaper = styled(Paper)(({theme}) => ({
  padding: theme.spacing(2),
  ...theme.typography.body2,
  backgroundColor: theme.palette.warning.main,
}));

interface DestColumnDraggableProps {
  sheetName: string,
  type: string,
  columns: { [key: string]: string },
}

export function SourceColumnDraggable(props: DestColumnDraggableProps) {
  return (<Box width={200}>
    <Typography variant={'h6'}>
      {props.type.charAt(0).toUpperCase() + props.type.slice(1)} {/* Capitalize the first letter */}
    </Typography>
    {Object.keys(props.columns).map((column) => (
      <DraggableColumn key={`dc-${props.type}-${column}`}
        column={column} sheetName={props.sheetName} type={props.type}/>
    ))}
  </Box>);
}

export interface DraggableColumnProps {
  column: string,
  sheetName: string,
  type: string,
}

function DraggableColumn(props: DraggableColumnProps) {
  const workforceImport = useAppSelector(selectWorkforceImport);
  const [{isDragging}, drag] = useDrag(() => ({
    type: 'wfi-column',
    item: {props},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const opacity = isDragging ? 0.4 : 1;

  // const id = `${props.type}-${column}`;
  const required = checkRequiredColumn(props.type, props.column);
  const found = checkMatchedColumn(props, props.column, workforceImport.columnMap);
  const reqChip = required ? <Chip color={'warning'} size="small"
    label={'*Required'} style={{marginLeft: '0.5rem'}}
  /> : <></>;

  const value = found ? <Box display={'flex'} justifyContent={'space-between'}>
    <Typography fontSize={14} color={(theme) => theme.palette.success.contrastText}>
      {props.column}
    </Typography>
    {reqChip}
  </Box> : <Box display={'flex'} justifyContent={'space-between'}>
    <Typography fontSize={14}>{props.column}</Typography>
    {reqChip}
  </Box>;

  return (
    <Box ref={drag} sx={{opacity}}>
      {found ?
        <SuccessPaper style={{userSelect: 'none', padding: '0.5rem', margin: '0.5rem'}}>
          {value}
        </SuccessPaper> :
        <Paper style={{userSelect: 'none', padding: '0.5rem', margin: '0.5rem'}}>
          {value}
        </Paper>
      }
    </Box>);
}


function checkRequiredColumn(type: string, column: string) {
  return !!RequiredColumns[type].find((item) => {
    return item == column;
  });
}

function checkMatchedColumn(props: DraggableColumnProps, column: string, columnMaps: ColumnMap[]) {
  return !!columnMaps.find((item) => {
    return item.type == props.type && item.instance == column && item.sheet == props.sheetName;
  });
}